.modal-schedule {
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    background-color: white;

    .block {
      position: relative;
      padding: 30px;

      /** Mobile. */
      @media (max-width: 1023px) {
        width: 100%;
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &:nth-child(3n + 0) {
        margin-right: 0;
      }

      &-hour,
      &-date,
      &-user,
      &-plan,
      &-schedule {
        margin-bottom: 10px;
      }

      &-user {
        margin-bottom: 10px;

        &--name {
          margin-bottom: 10px;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &--email {
          width: 100%;

          a {
            color: black;
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            transition: color time(default) ease(inout);

            strong {
              color: black;
            }

            &:hover {
              color: color(blue, normal);

              strong {
                color: black;
              }
            }
          }
        }
      }

      &-cta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
          background-color: color(blue, normal);
          color: white;
          font-size: 14px;
          font-weight: bold;
          padding: 15px 35px;
          display: inline-block;
          border: 1px solid color(blue, normal);
          transition: background-color time(default) ease(inout), color time(default) ease(inout);

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &:hover {
            background-color: transparent;
            color: color(blue, normal);
          }
        }
      }
    }
  }
}
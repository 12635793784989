.section-page-hero {
	position: relative;
	overflow: hidden;

	.background {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	.container {
		position: relative;
		z-index: 2;
	}

	.flex-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 200px;

		.wrapper {
			position: relative;
			display: inline-flex;
			align-items: center;

			.title {
				color: white;
				font-size: 25px;
				font-weight: 500;
				text-transform: uppercase;
			}

			.vector {
				position: absolute;
				top: 50%;
				left: calc(100% + 2px);
				transform: translateY(-50%);
				opacity: .1;

				/** Mobile. */
				@media (max-width: 1023px) {
					transform-origin: 0% 50%;
					transform: translateY(-50%) scale(0.3);
				}

				svg path {
					fill: black;
				}
			}
		}
	}
}
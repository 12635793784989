#page-terms {
	position: relative;
	width: 100%;
	padding: 60px 60px 0;
	overflow: hidden;

	/** Mobile. */
	@media (max-width: 1023px) {
		padding: 60px 0 0;
	}

	/** Section page hero. */
	.section-page-hero .background {
		background-image: linear-gradient(to right, #B91E92, #FF5E5F);
	}

	/** Section content. */
	.section-content {
		padding: 90px 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 50px 0 75px;
		}

		.content {
			width: 950px;
			margin: 0 auto;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}

			> * {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}

			p,
			div {
				color: #565656;
				font-size: 15px;
				font-weight: normal;
				line-height: 1.6;

				strong {
					color: color(blue, normal);
				}
			}

			ul li {
				position: relative;
				color: #565656;
				font-size: 15px;
				font-weight: normal;
				line-height: 1.6;
				padding-left: 30px;

				&:not(:last-child) {
					margin-bottom: 10px;
				}

				&::before {
					content: '';
					background-color: #F9F9FA;
					background-image: url('../assets/images/general/icon-list.png');
					background-size: auto;
					background-repeat: no-repeat;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					width: 21px;
					height: 21px;
				}
			}
		}
	}
}
#page-portal-daily {
	
	/** Section daily. */
	.section-daily {
		position: relative;
		padding: 55px 0;
		z-index: 2;

		.navigation ul {
			display: flex;
			align-items: center;
			justify-content: center;

			li {

				/** Mobile. */
				@media (max-width: 1023px) {
					flex-grow: 1;
				}

				&:not(:last-child) {
					margin-right: 40px;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin-right: 0;
					}
				}

				button {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 50px;
					padding: 0 25px;
					border-radius: 30px;
					overflow: hidden;

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 100%;
						border-radius: 0px;
					}

					&::before,
					&::after {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
					}

					&::before {
						background-image: linear-gradient(to right, #C0C0C0, #DCDDDD);
						z-index: 1;
					}

					&::after {
						background-image: linear-gradient(to right, #B91E92, #FF5E5F);
						z-index: 2;
						opacity: 0;
						transition: opacity time(default) ease(inout);
					}
					
					span {
						color: #3C3C3C;
						font-size: 16px;
						font-weight: bold;
						display: inline-block;
						position: relative;
						z-index: 3;
						transition: color time(default) ease(inout);
					}
				}

				&:hover button,
				&.active button {
					span {
						color: white;
					}

					&::after {
						opacity: 1;
					}
				}
			}
		}

		.tabs {
			position: relative;
			margin: 100px auto 0;
			transition: height time(default) ease(inout);
			transition-delay: .3s;

			/** Mobile. */
			@media (max-width: 1500px) {
				width: 100%;
			}
			@media (max-width: 1023px) {
				margin: 80px 0 0;
			}

			.tab-content {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				opacity: 0;
				visibility: hidden;
				transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

				&.active {
					opacity: 1;
					visibility: visible;
					transition-delay: .4s
				}

				/** Variations. */
				&[data-index="0"] {
					.flex-wrapper {
						display: flex;
						align-items: flex-start;

						/** Mobile. */
						@media (max-width: 1023px) {
							flex-wrap: wrap;
						}

						.slide {
							position: relative;
							width: 490px;

							/** Mobile. */
							@media (max-width: 1023px) {
								width: 100%;
							}

							&::before {
								content: '';
								position: absolute;
								top: 74px;
								right: 0;
								left: 0;
								height: 350px;
								z-index: 1;
								box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
								border-radius: 10px;
							}

							&-arrows {
								display: flex;
								align-items: center;
								justify-content: center;
								margin-bottom: 20px;

								.arrow {
									svg path {
										fill: color(grey);
										transition: fill time(default) ease(inout);
									}

									&:hover svg path {
										fill: color(blue, normal);
									}

									&:nth-child(2) {
										position: relative;
										margin-left: 50px;
										bottom: -1px;
									}
								}
							}

							&-items {
								position: relative;
								width: 100%;
								height: 386px;
								z-index: 2;

								.item {
									position: relative;
									width: 100%;

									.label {
										color: color(blue, normal);
										font-size: 16px;
										font-weight: bold;
										text-transform: uppercase;
										text-align: center;
										margin-bottom: 20px;
									}

									.calendar {
										background-color: white;
										position: relative;
										width: 100%;
										border-radius: 10px;
										overflow: hidden;

										&-header {
											background-color: color(blue, normal);

											ul {
												display: flex;
												align-items: center;
												flex-wrap: wrap;

												li {
													color: white;
													font-size: 14px;
													font-weight:  bold;
													text-transform: uppercase;
													height: 50px;
													width: 14%;
													display: flex;
													align-items: center;
													justify-content: center;

													/** Mobile. */
													@media (max-width: 1023px) {
														font-size: 12px;
													}
												}
											}
										}

										&-listing {
											padding: 30px 0;
											height: 300px;

											ul {
												display: flex;
												align-items: center;
												flex-wrap: wrap;
												width: 500px;
												height: 100%;

												/** Mobile. */
												@media (max-width: 1023px) {
													width: 100%;
												}

												li {
													height: 40px;
													width: 14%;

													button {
														position: relative;
														width: 100%;
														height: 100%;
														display: flex;
														align-items: center;
														justify-content: center;

														&::before,
														&::after {
															content: '';
															position: absolute;
															top: 50%;
															left: 50%;
															transform: translate(-50%, -50%);
															width: 28px;
															height: 28px;
															border-radius: 50%;
														}

														&::before {
															transition: background-color time(fast) ease(inout);
															z-index: 1;
														}

														&::after {
															background-image: linear-gradient(to right, #B91E92, #FF5E5F);
															opacity: 0;
															transition: opacity time(fast) ease(inout);
															z-index: 2;
														}

														span {
															position: relative;
															color: #989898;
															font-size: 14px;
															font-weight: bold;
															transition: color time(default) ease(inout);
															z-index: 3;
														}
													}

													/** Desktop. */
													@media (min-width: 1024px) {
														&:hover button span {
															color: white;
														}
													}

													&.current button span,
													&.active button span,
													&.full button span {
														color: white;
													}

													/** Desktop. */
													@media (min-width: 1024px) {
														&:hover button::after {
															opacity: 1;
														}
													}

													&.full button::before {
														background-color: color(blue, normal);
													}

													&.current button::before {
														background-color: #989898;
													}

													&.inactive {
														opacity: .5;
														pointer-events: none;
													}
												}
											}
										}
									}
								}
							}

							&-legend {
								display: flex;
								align-items: center;
								margin-top: 20px;

								/** Mobile. */
								@media (max-width: 1023px) {
									flex-wrap: wrap;
								}

								span {
									color: color(blue, normal);
									font-size: 15px;
									font-weight: normal;
									margin: 0;
									padding: 0;

									/** Mobile. */
									@media (max-width: 1023px) {
										font-size: 14px;
									}
								}

								ul {
									position: relative;
									flex-grow: 1;
									bottom: -2px;
									display: flex;
									align-items: center;
									justify-content: space-between;
									margin-left: 20px;

									/** Mobile. */
									@media (max-width: 1023px) {
										flex-wrap: wrap;
										margin: 20px 0 0;
									}

									li {
										position: relative;
										padding: 0 0 0 15px;

										/** Mobile. */
										@media (max-width: 1023px) {
											width: 100%;

											&:not(:last-child) {
												margin-bottom: 10px;
											}
										}

										&::before {
											content: '';
											position: absolute;
											top: 50%;
											left: 0;
											transform: translateY(-50%);
											width: 10px;
											height: 10px;
											border-radius: 50%;
										}

										span {
											color: #565656;
										}

										&.current::before {
											background-color: #989898;
										}

										&.active::before {
											background-color: color(blue, normal);
										}

										&.full::before {
											background-color: #BE1E2D;
										}
									}
								}
							}
						}

						.wrapper {
							position: relative;
							padding-top: 20px;
							margin-left: 140px;

							/** Mobile. */
							@media (max-width: 1500px) {
								margin-left: 50px;
							}
							@media (max-width: 1023px) {
								padding-top: 0;
								margin: 40px 0 0;
							}

							&::before {
								content: '';
								background-color: #C0C0C1;
								width: 1px;
								height: 275px;
								position: absolute;
								top: 95px;
								left: -60px;

								/** Mobile. */
								@media (max-width: 1500px) {
									left: -25px;
								}
								@media (max-width: 1023px) {
									display: none;
								}
							}

							&-title {
								color: color(blue, normal);
								font-size: 16px;
								font-weight: bold;
								text-transform: uppercase;
								text-align: center;
								margin-bottom: 40px;
							}

							&-form {
								.form {
									.form-row {
										display: flex;
										align-items: flex-start;

										/** Mobile. */
										@media (max-width: 1023px) {
											flex-wrap: wrap;
										}

										&:not(:last-child) {
											margin-bottom: 35px;
										}

										.field-group {
											flex-grow: 1;
											position: relative;

											&:not(:last-child) {
												margin-right: 30px;

												/** Mobile. */
												@media (max-width: 1023px) {
													margin: 0 0 35px;
												}
											}

											.placeholder {
												color: #565656;
												font-size: 15px;
												font-weight: 500;
												position: absolute;
												top: 3px;
												left: 0;
												pointer-events: none;
												transform-origin: 0% 0%;
												white-space: nowrap;
												transition: transform time(default) ease(inout);


												/** Mobile. */
												@media (max-width: 1500px) {
													font-size: 12px;
													top: 5px;
												}
											}

											.field {
												background-color: transparent;
												color: #565656;
												font-size: 15px;
												font-weight: 500;
												width: 100%;
												padding-bottom: 5px;
												border: none;
												border-bottom: 1px solid #3C3C3C;
												transition: border-color time(default) ease(inout);

												&::placeholder {
													opacity: 0;
													transition: opacity time(default) ease(inout);
												}
											}

											.subtitle {
												color: #565656;
												font-size: 12px;
												font-weight: normal;
												position: relative;
												margin-top: 5px;
												line-height: 1.3;
											}

											textarea {
												height: 60px;
												resize: none;
											}

											&.focus,
											&.not-empty {
												.placeholder {
													transform: translateY(-20px) scale(.8);
												}

												.field {
													border-color: color(blue, normal);

													&::placeholder {
														opacity: 1;
													}
												}
											}

											&.invalid .field {
												border-color: red;
											}
										}
									}

									.form-submit {
										margin-top: 30px;

										/** Mobile. */
										@media (max-width: 1023px) {
											display: flex;
											justify-content: center;
										}

										button {
											background-image: linear-gradient(to right, #174E98, #49A7E3, #174E98);
											background-size: 200% 100%;
											background-position: left center;
											display: flex;
											align-items: center;
											justify-content: center;
											width: 180px;
											height: 50px;
											margin: 0;
											border-radius: 50px;
											transition: background-position time(default) ease(inout);

											&:hover {
												background-position: right center;
											}

											span {
												color: white;
												font-size: 16px;
												font-weight: bold;
												text-transform: uppercase;
												margin-right: 10px;
											}

											svg path {
												fill: white;
											}
										}
									}
								}
							}
						}
					}
				}
				
				&[data-index="1"] {
					.slide {
						position: relative;
						width: 100%;

						&::before {
							content: '';
							position: absolute;
							top: 74px;
							right: 0;
							left: 0;
							height: 215px;
							z-index: 1;
							box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
							border-radius: 20px;

							/** Mobile. */
							@media (max-width: 1200px) {
								display: none;
							}
						}

						&-arrows {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-bottom: 20px;

							.arrow {
								svg path {
									fill: color(grey);
									transition: fill time(default) ease(inout);
								}

								&:hover svg path {
									fill: color(blue, normal);
								}

								&:nth-child(2) {
									position: relative;
									margin-left: 50px;
									bottom: -1px;
								}
							}
						}

						&-items {
							position: relative;
							width: 100%;
							height: 250px;
							z-index: 2;

							/** Mobile. */
							@media (max-width: 1200px) {
								height: auto;
							}

							.item {
								position: relative;
								width: 100%;

								.label {
									color: color(blue, normal);
									font-size: 16px;
									font-weight: bold;
									text-transform: uppercase;
									text-align: center;
									margin-bottom: 20px;
								}

								&-table {
									.item-line {
										position: relative;
										width: 100%;

										&:not(:last-child) {
											margin-bottom: 20px;
										}

										&--header {
											color: color(blue, normal);
											font-size: 16px;
											font-weight: bold;
											text-transform: uppercase;
											display: flex;
											align-items: center;
											justify-content: center;
											width: 100%;
											height: 55px;
										}

										&--info {
											position: relative;
											width: 100%;

											.item-info {
												position: relative;
												width: 100%;
												display: flex;
												align-items: center;

												&--label {
													background-color: color(blue, normal);
													color: white;
													font-size: 16px;
													font-weight: bold;
													padding: 0;
													width: 100px;
													height: 40px;
													display: flex;
													align-items: center;
													padding: 0 10px;
												}

												&--value {
													color: #989898;
													font-size: 16px;
													font-weight: bold;
													padding: 0;
													flex-grow: 1;
													padding-left: 15px;
													height: 40px;
													display: flex;
													align-items: center;
												}
											}
										}
									}
								}

								&-slide {
									background-color: #F3F3F5;
									display: flex;
									width: 100%;
									height: 215px;
									border-radius: 20px;
									overflow: hidden;

									&--left {
										width: 180px;
										flex-shrink: 0;

										.headline {
											display: flex;
											align-items: center;
											justify-content: center;
											padding: 0 20px;
											height: 55px;
											border-right: 1px solid #CECED0;

											.title {
												color: color(blue, normal);
												font-size: 16px;
												font-weight: bold;
												text-transform: uppercase;
											}

											.arrows {
												display: flex;
												align-items: center;
												justify-content: center;
												position: relative;
												top: -1px;
												margin-left: 20px;

												.owl-prev,
												.owl-next {
													color: color(blue, normal);
													font-size: 14px;
													font-weight: bold;
													text-transform: uppercase;
												}

												.owl-prev {
													margin-right: 10px;
												}
											}
										}

										.listing {
											background-color: color(blue, normal);

											&-item {
												color: white;
												font-size: 16px;
												font-weight: bold;
												padding: 0;
												height: 40px;
												display: flex;
												align-items: center;
												padding: 0 10px;

												&:not(:last-child) {
													border-bottom: 1px solid #2C649D;
												}
											}
										}
									}

									&--right {
										.item-slide-items {
											position: relative;
											width: 100%;
											height: 215px;

											.item {
												.headline {
													color: color(blue, normal);
													font-size: 16px;
													font-weight: bold;
													text-transform: uppercase;
													display: flex;
													align-items: center;
													justify-content: center;
													width: 100%;
													height: 55px;
												}

												.listing {
													&-item {
														color: #989898;
														font-size: 16px;
														font-weight: bold;
														padding: 0 15px;
														width: auto;
														height: 40px;
														display: flex;
														align-items: center;
														justify-content: center;

														&:not(:last-child) {
															border-bottom: 1px solid #CECED0;
														}
													}
												}

												&.current {
													background-color: color(blue, normal);
													border-right: none !important;

													.headline {
														color: white;
													}

													.listing .listing-item {
														color: white;
													}
												}
											}

											.owl-item {
												&:not(:last-child) {
													.item {
														border-right: 1px solid #CECED0;
													}
												}
											}
										}
									}
								}
							}
						}

						&-legend {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-top: 20px;

							/** Mobile. */
							@media (max-width: 1200px) {
								display: none;
							}

							span {
								color: color(blue, normal);
								font-size: 15px;
								font-weight: normal;
								margin: 0;
								padding: 0;
							}

							ul {
								position: relative;
								bottom: -2px;
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin-left: 20px;

								li {
									position: relative;
									padding: 0 0 0 15px;

									&::before {
										content: '';
										position: absolute;
										top: 50%;
										left: 0;
										transform: translateY(-50%);
										width: 10px;
										height: 10px;
										border-radius: 50%;
									}

									span {
										color: #565656;
									}

									&.current::before {
										background-color: color(blue, normal);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.modal-plans-payment {
	display: flex;
	align-items: center;
	justify-content: center;

	/** Mobile. */
	@media (max-width: 1023px) {
		background-color: white;
		display: block;
		overflow: auto;
	}

	.wrapper {
		background-color: white;
		position: relative;
		width: 915px;
		border-radius: 30px;
		padding: 35px 100px 50px;

		/** Mobile. */
		@media (max-width: 1023px) {
			width: 100%;
			border-radius: 0px;
			padding: 60px 20px 30px;
			overflow: hidden;
		}

		&-close {
			display: flex;
			align-items: center;
			position: absolute;
			top: -25px;
			right: 30px;
			z-index: 3;

			/** Mobile. */
			@media (max-width: 1023px) {
				top: 15px;
				right: 15px;
			}

			span {
				color: white;
				font-size: 12px;
				font-weight: bold;
				text-transform: uppercase;
				margin-right: 10px;
				transition: color time(default) ease(inout);

				/** Mobile. */
				@media (max-width: 1023px) {
					color: color(blue, normal);
				}
			}

			svg path {
				fill: white;
				transition: fill time(default) ease(inout);

				/** Mobile. */
				@media (max-width: 1023px) {
					fill: color(blue, normal);
				}
			}

			/** Desktop. */
			@media (min-width: 1024px) {
				&:hover {
					span {
						color: color(blue, light);
					}

					svg path {
						fill: color(blue, light);
					}
				}
			}
		}

		&-title {
			color: color(blue, normal);
			font-size: 16px;
			font-weight: bold;
			text-transform: uppercase;
			text-align: center;
			display: block;
			margin-bottom: 35px;
		}

		&-navigation ul {
			display: flex;
			align-items: flex-start;
			justify-content: center;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
			}

			li {
				position: relative;

				&:not(:last-child) {
					margin-right: 40px;
				}

				/** Mobile. */
				@media (max-width: 1023px) {
					margin: 0 30px 40px;
				}
				
				button {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					&::before {
						content: '';
						background-color: #E2E2E2;
						position: absolute;
						width: 10px;
						height: 10px;
						border-radius: 50px;
					}

					&::after {
						content: '';
						background-image: linear-gradient(to bottom right, #1D59A2, #3E93D2);
						position: absolute;
						width: 6px;
						height: 6px;
						border-radius: 50px;
						opacity: 0;
						transition: opacity time(default) ease(inout);
					}

					span {
						color: #989898;
						font-size: 16px;
						font-weight: bold;
						display: inline-block;
						margin-bottom: 10px;
						transition: color time(default) ease(inout);
					}

					img {
						user-select: none;
					}
				}

				// &:nth-child(1) button {
				// 	padding-left: 20px;

				// 	&::before,
				// 	&::after {
				// 		top: 50%;
				// 		transform: translateY(-50%);
				// 	}

				// 	&::before {
				// 		left: 0;
				// 	}

				// 	&::after {
				// 		left: 2px;
				// 	}
				// }

				&:nth-child(1) button {
					&::before,
					&::after {
						left: calc(50% - 65px);
						transform: translateX(-50%);
					}

					&::before {
						top: 2px;
					}

					&::after {
						top: 4px;
					}
				}

				&:nth-child(2) button {
					&::before,
					&::after {
						left: calc(50% - 85px);
						transform: translateX(-50%);
					}

					&::before {
						top: 2px;
					}

					&::after {
						top: 4px;
					}
				}

				&.active button::after {
					opacity: 1;
				}

				&:hover button span {
					color: color(blue, normal);
				}
			}
		}

		&-tabs {
			position: relative;
			margin: 40px 0 20px;
			transition: height time(default) ease(inout);
			transition-delay: .2s;

			.tab-content {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				opacity: 0;
				visibility: hidden;
				transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

				&.active {
					opacity: 1;
					visibility: visible;
					transition-delay: .4s
				}

				.content {
					text-align: center;

					.description {
						p {
							color: color(grey);
							font-size: 16px;
							font-weight: normal;
							line-height: 1.7;
						}
					}

					&-paypal {
						button {
							background-image: linear-gradient(to right, #174E98, #49A7E3);
							display: flex;
							align-items: center;
							justify-content: center;
							width: 220px;
							height: 50px;
							margin: 30px auto 0;
							border-radius: 50px;

							span {
								color: white;
								font-size: 16px;
								font-weight: bold;
								text-transform: uppercase;
								margin-right: 13px;
							}

							svg path {
								fill: white;
							}
						}
					}
				}
			}
		}

		&-message {

			/** Mobile. */
			@media (max-width: 1023px) {
				margin-top: 25px;
			}

			p {
				color: color(grey);
				font-size: 9px;
				font-weight: normal;
				line-height: 1.4;
			}
		}
	}
}
#page-how-it-works {
	position: relative;
	width: 100%;
	padding: 60px 60px 0;
	overflow: hidden;

	/** Mobile. */
	@media (max-width: 1200px) {
		padding: 60px 0 0;
	}

	/** Section page hero. */
	.section-page-hero .background {
		background-image: linear-gradient(to right, #7D3FEF, #FD90E2);
	}

	/** Section content. */
	.section-content {
		padding: 90px 0 70px;

		/** Mobile. */
		@media (max-width: 1200px) {
			padding: 50px 0 75px;
		}

		.description {
			margin-bottom: 70px;

			p {
				color: color(grey);
				font-size: 15px;
				font-weight: 500;
				line-height: 1.7;
			}
		}

		.flex-wrapper {
			display: flex;
			align-items: flex-start;

			.left {
				position: relative;
				flex-shrink: 0;
				width: 720px;

				/** Mobile. */
				@media (max-width: 1200px) {
					display: none;
				}

				.progress {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					pointer-events: none;

					svg path {
						fill: #87868A;
					}
				}

				.items {
					position: relative;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					width: 100%;
					height: 340px;
					padding: 20px 58px 0 52px;

					.item {
						position: relative;
						text-align: center;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						&:nth-child(even) {
							top: 130px;
						}
						
						&-count {
							color: color(blue, normal);
							font-size: 20px;
							font-weight: bold;
							margin: 0;
							padding: 0;
						}

						&-icon {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 45px;
							margin: 10px 0 15px;

							svg path {
								fill: color(blue, normal);
							}
						}

						&-title {
							color: color(blue, normal);
							font-size: 15px;
							font-weight: normal;
							line-height: 1.5;
							width: 110px;
						}
					}
				}
			}

			.right {
				flex-grow: 1;
				margin-left: 60px;

				/** Mobile. */
				@media (max-width: 1200px) {
					margin-left: 0;
				}

				.accordions {
					.accordion {
						position: relative;
						border-bottom: 1px solid #E2E2E4;

						&:not(:last-child) {
							margin-bottom: 15px;
						}
						
						&-header {
							display: flex;
							align-items: center;
							cursor: pointer;
							height: 50px;
							width: 100%;
							
							svg {
								transform-origin: 0% 50%;
								transform: scale(.7);

								path {
									fill: color(dark);
									transition: fill time(default) ease(inout);
								}
							}

							span {
								color: color(dark);
								font-size: 15px;
								font-weight: 500;
								position: relative;
								top: -1px;
								transition: color time(default) ease(inout);

								&:nth-child(1) {
									color: color(dark);
									font-weight: bold;
									margin-right: 10px;
								}
							}
						}

						&-wrapper {
							transition: height time(default) ease(inout);
							overflow: hidden;

							&--content {
								position: relative;
								padding: 20px 0;
								
								p {
									color: color(grey);
									font-size: 15px;
									font-weight: normal;
									line-height: 1.7;
								}
							}
						}

						/** Desktop. */
						@media (min-width: 1024px) {
							&.active .accordion-header,
							&:hover .accordion-header {
								svg path {
									fill: color(blue, normal);
								}

								span {
									color: color(blue, normal);
								}
							}
						}

						/** Mobile. */
						@media (max-width: 1023px) {
							&.active .accordion-header {
								svg path {
									fill: color(blue, normal);
								}

								span {
									color: color(blue, normal);
								}
							}
						}
					}
				}
			}
		}
	}

	/** Section video. */
	.section-video {
		position: relative;
		height: 445px;

		/** Mobile. */
		@media (max-width: 1023px) {
			height: 260px;
			margin: 60px 0 0;
		}

		.background {
			background-color: color(blue, normal);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				opacity: .3;
			}
		}

		.wrapper {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			z-index: 2;

			&-link {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 5;
			}

			&-icon {
				position: relative;
				margin: 0 0 15px;
				padding: 0;

				img {
					display: inline-block;
					user-select: none;
				}
			}

			&-title {
				color: white;
				font-size: 15px;
				font-weight: 500;
				display: inline-block;

				/** Mobile. */
				@media (max-width: 1023px) {
					padding: 0 20px;
					line-height: 1.4;
				}
			}
		}
	}
}
#page-about {
	position: relative;
	width: 100%;
	padding: 60px 60px 0;
	overflow: hidden;

	/** Mobile. */
	@media (max-width: 1200px) {
		padding: 60px 0 0;
	}

	/** Section page hero. */
	.section-page-hero .background {
		background-image: linear-gradient(to right, #24A6EC, #3FEFE3);
	}

	/** Section about. */
	.section-about {
		position: relative;
		padding: 85px 0 60px;
		z-index: 2;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 50px 0;
		}

		.flex-wrapper {
			display: flex;
			align-items: flex-start;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
			}

			.left {
				position: relative;
				width: 420px;
				height: 630px;
				top: -140px;
				flex-shrink: 0;

				/** Mobile. */
				@media (max-width: 1023px) {
					width: 100%;
					height: auto;
					top: unset;
				}

				&-image {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 1;

					/** Mobile. */
					@media (max-width: 1023px) {
						position: relative;
					}

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;

						/** Mobile. */
						@media (max-width: 1023px) {
							height: auto;
						}
					}
				}
			}

			.right {
				flex-grow: 1;
				margin-left: 65px;

				/** Mobile. */
				@media (max-width: 1023px) {
					margin: 40px 0 0;
				}

				&-description {
					p {
						color: color(grey);
						font-size: 15px;
						font-weight: normal;
						line-height: 1.7;
						margin: 0;
						padding: 0;

						strong {
							color: color(blue, normal);
						}

						&:not(:last-child) {
							margin-bottom: 25px;
						}
					}
				}
			}
		}
	}
}
#main-footer {
	background-color: #F9F9FA;
	position: relative;
	padding: 40px 60px;

	/** Mobile. */
	@media (max-width: 1200px) {
		padding: 40px 0;
	}

	.flex-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 70px;

		/** Mobile. */
		@media (max-width: 1200px) {
			flex-wrap: wrap;
			padding: 0 25px;
		}

		.newsletter {
			opacity: 0;
			transition: opacity time(default) ease(inout);

			/** Mobile. */
			@media (max-width: 1200px) {
				width: 100%;
			}

			&-title {
				color: color(blue, normal);
				font-size: 16px;
				font-weight: bold;
				text-transform: uppercase;

				/** Mobile. */
				@media (max-width: 1200px) {
					text-align: center;
				}
			}

			&-form {
				margin-top: 10px;

				.form {
					display: flex;
					align-items: center;

					/** Mobile. */
					@media (max-width: 1200px) {
						justify-content: center;
					}

					.field-group {
						position: relative;
						margin-right: 10px;
						
						.placeholder {
							color: color(dark);
							font-size: 15px;
							font-weight: 500;
							position: absolute;
							top: 3px;
							left: 0;
							pointer-events: none;
							transition: opacity time(default) ease(inout), transform time(default) ease(inout);

							/** Mobile. */
							@media (max-width: 355px) {
								font-size: 12px;
							}
						}

						.field {
							background-color: transparent;
							color: color(dark);
							font-size: 15px;
							font-weight: 500;
							width: 275px;
							padding-bottom: 5px;
							border: none;
							border-bottom: 1px solid color(blue, normal);
							transition: border-color time(default) ease(inout);

							/** Mobile. */
							@media (max-width: 355px) {
								width: 210px;
								font-size: 12px;
							}
						}

						&.focus,
						&.not-empty {
							.placeholder {
								opacity: 0;
								transform: translateX(10px);
							}
						}

						&.invalid .field {
							border-color: red;
						}
					}

					.form-submit {
						button {
							display: inline-block;

							svg {
								transition: transform time(default) ease(inout);
							}

							&:hover svg {
								transform: translateX(10px);
							}
						}
					}
				}
			}
		}

		.info {
			/** Mobile. */
			@media (max-width: 1200px) {
				width: 100%;
				margin: 35px 0;
			}

			&-item {
				display: flex;
				align-items: center;
				opacity: 0;
				transform: translateX(10px);
				transition: opacity time(default) ease(inout), transform time(slow) ease(inout);

				/** Mobile. */
				@media (max-width: 1200px) {
					justify-content: center;
				}

				&:not(:last-child) {
					margin-bottom: 7px;

					/** Mobile. */
					@media (max-width: 1200px) {
						margin-bottom: 10px;
					}
				}

				&--label,
				&--value {
					color: color(dark);
					font-size: 13px;
				}

				&--label {
					font-weight: bold;
					margin-right: 5px;
				}

				&--value {
					font-weight: 500;
				}
			}
		}

		.signature {
			display: flex;
			align-items: center;
			opacity: 0;
			transition: opacity time(default) ease(inout);

			/** Mobile. */
			@media (max-width: 1200px) {
				width: 100%;
				justify-content: center;
			}

			.copyright {
				span {
					color: color(dark);
					font-size: 12px;
					font-weight: normal;
					line-height: 1.2;
				}
			}

			.vive {
				margin-left: 30px;

				a {
					display: inline-block;
					
					img {
						transition: filter time(default) ease(inout);
					}

					&:hover img {
						filter: grayscale(100%);
					}
				}
			}
		}

		/** Appear. */
		&.appear {
			.newsletter {
				opacity: 1;
			}

			.info {
				&-item {
					opacity: 1;
					transform: translateX(0);

					/** @each: Delay. */
					@for $i from 1 to 5 {
						&:nth-child(#{$i}) {
							transition-delay: #{.15s * $i};
						}
					}
				}
			}

			.signature {
				opacity: 1;
				transition-delay: .3s;
			}
		}
	}
}
.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 105;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

	&.open {
		opacity: 1;
		visibility: visible;
		pointer-events: initial;
	}
}
.page-portal {
	position: relative;
	width: 100%;
	padding: 60px 60px 0 230px;
	overflow: hidden;

	/** Mobile. */
	@media (max-width: 1200px) {
		padding: 60px 0 0;
	}

	/** Section hero. */
	.section-page-hero {
		.background {
			background-image: linear-gradient(to right, #B91E92, #FF5E5F);
		}

		.flex-wrapper {
			height: 130px;

			.wrapper .vector {
				transform-origin: 0% 0%;
				transform: scale(.7) translateY(-50%);
			}
		}

		&::after {
			content: '';
			background-color: white;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			width: 60px;
			z-index: 50;

			/** Mobile. */
			@media (max-width: 1200px) {
				display: none;
			}
		}
	}
	
	/** General container. */
	.container {

		/** Mobile. */
		@media (max-width: 1500px) {
			padding: 0 50px;
		}
		@media (max-width: 1200px) {
			padding: 0 25px;
		}
	}
}

#main-portal {
	position: fixed;
	background-color: white;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 60;
	width: 230px;
	box-shadow: 0px 3px 7.12px 0.88px rgba(0, 0, 0, 0.06);

	/** Mobile. */
	@media (max-width: 1200px) {
		display: none;
	}

	.back-website {
		position: absolute;
		top: 22px;
		left: 280px;
		white-space: nowrap;

		a {
			display: inline-flex;
			align-items: center;

			svg {
				position: relative;
				top: -1px;
				transition: transform time(default) ease(inout);
				margin-right: 10px;

				path {
					fill: #404040;
				}
			}

			span {
				color: #404040;
				font-size: 10px;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: .8px;
			}

			&:hover svg {
				transform: translateX(-10px);
			}
		}
	}

	.logout {
		position: absolute;
		top: 22px;
		left: calc(100vw - 200px);

		/** Mobile. */
		@media (max-width: 1200px) {
			display: none;
		}
		
		a {
			color: #404040;
			font-size: 10px;
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: .8px;
			white-space: nowrap;
			transition: color time(default) ease(inout);

			&:hover {
				color: color(blue, normal);
			}
		}
	}

	.logo {
		display: block;
		padding: 15px 0 0 25px;
	}

	.member {
		display: flex;
		align-items: center;
		margin-top: 70px;
		padding: 0 25px;

		&-image {
			width: 41px;
			height: 41px;
			border-radius: 50%;
			overflow: hidden;
			flex-shrink: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-content {
			width: 130px;
			margin-left: 10px;

			&--title {
				color: #404040;
				font-size: 10px;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: .8px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&--plan {
				color: #989898;
				font-size: 10px;
				font-weight: bold;
				text-transform: uppercase;
				margin-top: 3px;
			}
		}
	}

	.navigation {
		margin-top: 55px;

		ul li {
			position: relative;
			display: block;

			&:not(:last-child) {
				margin-bottom: 5px;
			}

			&::before {
				content: '';
				background-image: linear-gradient(to right, #B91E92, #FF5E5F);
				position: absolute;
				top: 0;
				right: 13px;
				bottom: 0;
				left: 0;
				border-radius: 0 20px 20px 0;
				transform: translateX(-100%);
				transition: transform time(default) ease(inout);
				z-index: 2;
			}
			
			a {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 37px;
				padding: 0 35px 0 25px;
				z-index: 3;

				span {
					color: #989898;
					font-size: 13px;
					font-weight: bold;
					display: inline-block;
					transition: color time(default) ease(inout);
					text-transform: capitalize;
					transition-delay: .2s;
				}

				svg path {
					fill: #989898;
					transition: fill time(default) ease(inout);
				}
			}

			&:hover,
			&.active {
				&::before {
					transform: translateX(0);
				}

				a {
					span {
						color: white;
						transition-delay: 0s;
					}

					svg path {
						fill: white;
						transition-delay: .2s;
					}
				}
			}

			&.upload {
				&::after {
					content: '';
					background-image: linear-gradient(to right, #C0871B, #FFBF00);
					position: absolute;
					top: 0;
					right: 14px;
					bottom: 0;
					left: 0;
					border-radius: 0 20px 20px 0;
					z-index: 1;
				}

				a {
					span {
						color: white;
						transition-delay: 0s;
					}

					svg {
						position: relative;
						top: -1px;

						path {
							fill: white;
							transition-delay: 0s;
						}
					}
				}
			}

			&.inactive {
				opacity: .5 !important;
				pointer-events: none !important;
			}
		}
	}
}

#main-portal-mobile {
	background-image: linear-gradient(to right bottom, #B91E92, #FF5E5F);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 60;
	opacity: 0;
	visibility: hidden;
	transition: opacity time(default) ease(inout), visibility time(default) ease(inout);
	pointer-events: none;

	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: initial;
	}

	.logo {
		position: absolute;
		top: 13px;
		left: 25px;

		a {
			display: inline-block;
		}
	}

	.close {
		position: absolute;
		top: 23px;
		right: 29px;
		z-index: 5;

		svg path {
			fill: white;
		}
	}

	.wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			justify-content: center;
		}

		.member {
			display: flex;
			align-items: center;

			&-image {
				width: 41px;
				height: 41px;
				border-radius: 50%;
				overflow: hidden;
				flex-shrink: 0;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			&-content {
				width: 200px;
				margin-left: 10px;

				&--title {
					color: white;
					font-size: 10px;
					font-weight: bold;
					text-transform: uppercase;
					letter-spacing: .8px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				&--plan {
					color: fade-out(white, .2);
					font-size: 10px;
					font-weight: bold;
					text-transform: uppercase;
					margin-top: 3px;
				}
			}
		}

		.navigation {
			width: 200px;
			margin-top: 15px;

			/** Mobile. */
			@media (max-width: 355px) {
				margin-top: 10px;
			}

			ul li {
				position: relative;
				display: block;

				&:not(:last-child) {
					margin-bottom: 5px;

					/** Mobile. */
					@media (max-width: 355px) {
						margin-bottom: 2px;
					}
				}
				
				a {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 37px;
					z-index: 3;

					span {
						color: white;
						font-size: 18px;
						font-weight: bold;
						display: inline-block;
						margin-right: 10px;
					}

					svg path {
						fill: white;
					}
				}

				&.active a {
					span {
						color: black;
					}

					svg path {
						fill: black;
					}
				}

				&.inactive {
					opacity: .5 !important;
					pointer-events: none !important;
				}
			}
		}

		.flex {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 35px;

			/** Mobile. */
			@media (max-width: 355px) {
				margin-top: 15px;
			}

			.back-website {
				position: relative;
				white-space: nowrap;
				margin-right: 25px;

				a {
					display: inline-flex;
					align-items: center;

					svg {
						position: relative;
						top: -1px;
						margin-right: 10px;

						path {
							fill: white;
						}
					}

					span {
						color: white;
						font-size: 10px;
						font-weight: bold;
						text-transform: uppercase;
						letter-spacing: .8px;
					}
				}
			}

			.logout {
				position: relative;
				top: -2px;
				
				a {
					color: white;
					font-size: 10px;
					font-weight: bold;
					text-transform: uppercase;
					letter-spacing: .8px;
					white-space: nowrap;
				}
			}
		}
	}
}

#main-portal-toggler {
	position: fixed;
	top: 20px;
	right: 25px;
	width: 25px;
	height: 20px;
	z-index: 55;

	span {
		background-image: linear-gradient(to right, #B91E92, #FF5E5F);
		position: absolute;
		height: 2px;
		right: 0;
		left: 0;
		transition: background-color time(default) ease(inout);

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2) {
			top: calc(50% - 1px);
		}

		&:nth-child(3) {
			bottom: 0;
		}
	}
}
#page-schedule {
	position: relative;
	width: 100%;
	padding: 60px 60px 0;
	overflow: hidden;

	/** Mobile. */
	@media (max-width: 1023px) {
		padding: 60px 0 0;
	}

	/** Section page hero. */
	.section-page-hero .background {
		background-image: linear-gradient(to right, #7D3FEF, #FD90E2);
	}

	/** Section schedule. */
	.section-schedule {
		padding: 100px 0;

		.slide-arrows {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 50px;

			.arrow {
				svg path {
					fill: color(grey);
					transition: fill time(default) ease(inout);
				}

				&:hover svg path {
					fill: color(blue, normal);
				}

				&:nth-child(2) {
					position: relative;
					margin-left: 50px;
					bottom: -1px;
				}
			}
		}

		.flex-wrapper {
			display: flex;

			.tabs {
				position: relative;
				width: 100%;

				.tab {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					display: flex;
					opacity: 0;
					visibility: hidden;
					transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

					&.active {
						opacity: 1;
						visibility: visible;
					}

					.left {
						flex-shrink: 0;
						width: 130px;
						padding-top: 103px;

						/** Mobile. */
						@media (max-width: 1023px) {
							width: 100px;
						}

						&-times {
							width: 100%;

							.item {
								background-color: color(blue, normal);
								color: white;
								font-size: 16px;
								font-weight: bold;
								display: flex;
								align-items: center;
								justify-content: center;
								height: 35px;
								border-bottom: 1px solid fade-out(black, .7);
							}
						}
					}

					.right {
						width: calc(100% - 130px);

						/** Mobile. */
						@media (max-width: 1023px) {
							width: calc(100% - 100px);
						}

						&-title {
							color: color(grey);
							font-size: 18px;
							font-weight: bold;
							text-align: center;
							width: calc(100% + 130px);
							margin: 0 0 30px -130px;
						}

						&-slide {
							.item {
								background-color: white;
								color: color(grey);
								font-size: 16px;
								font-weight: bold;
								border-right: 1px solid fade-out(black, .7);

								&-header {
									background-color: #F3F3F5;
									color: color(blue, normal);
									font-size: 16px;
									font-weight: bold;
									display: flex;
									align-items: center;
									justify-content: center;
									border-bottom: 1px solid fade-out(black, .7);
									height: 55px;

									span {
										color: #848484;
										margin-left: 5px;
									}
								}

								&-listing {
									position: relative;

									&--info {
										position: relative;
										color: color(grey);
										font-size: 16px;
										font-weight: bold;
										display: flex;
										align-items: center;
										justify-content: center;
										height: 35px;
										border-bottom: 1px solid fade-out(black, .7);

										&.full {
											background-color: #BE1E2D;
										}

										button {
											background-color: #AACAEB;
											color: #525252;
											font-size: 14px;
											font-weight: bold;
											text-transform: uppercase;
											position: relative;
											display: flex;
											align-items: center;
											justify-content: flex-start;
											white-space: nowrap;
											text-overflow: ellipsis;
											overflow: hidden;
											padding: 0 10px;
											width: 100%;
											height: 100%;
										}
									}
								}

								&.active {
									background-color: #F3F3F5;

									.item-header {
										background-color: color(blue, normal);
										color: white;

										span {
											color: black;
										}
									}
								}

								&.full {
									background-color: #BE1E2D;
								}
							}
						}
					}
				}
			}
		}
	}
}
#page-portal-data {
	
	/** Section data. */
	.section-data {
		position: relative;
		padding: 55px 0;
		z-index: 2;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 50px 0;
		}

		.wrapper {
			.message-personal {
				position: relative;
				text-align: center;
				margin-bottom: 60px;
				
				p {
					color: black;
					font-size: 16px;
					font-weight: normal;
					line-height: 1.7;

					a {
						color: color(blue, normal);
						font-weight: bold;
						transition: color time(default) ease(inout);

						&:hover {
							color: black;
						}
					}
				}
			}

			.form {
				.form-description {
					margin-bottom: 40px;

					h3 {
						color: color(blue, normal);
						font-size: 16px;
						font-weight: bold;
						text-transform: uppercase;
						margin-bottom: 20px;
					}

					p {
						color: #565656;
						font-size: 15px;
						font-weight: normal;
						line-height: 1.5;
					}
				}

				.form-title {
					color: color(blue, normal);
					font-size: 24px;
					font-weight: bold;
					text-transform: uppercase;
					text-align: center;
					margin: 100px 0 50px;

					&:nth-child(2) {
						margin-top: 0;
					}
				}

				.form-row {
					display: flex;
					align-items: center;

					/** Mobile. */
					@media (max-width: 1023px) {
						flex-wrap: wrap;
					}

					&:not(:last-child) {
						margin-bottom: 35px;
					}

					.field-group {
						flex-grow: 1;
						position: relative;

						/** Mobile. */
						@media (max-width: 1023px) {
							flex-grow: unset;
							width: 100%;
						}

						&:not(:last-child) {
							margin-right: 30px;

							/** Mobile. */
							@media (max-width: 1023px) {
								margin-right: 0;
								margin-bottom: 35px;
							}
						}

						.placeholder {
							color: #565656;
							font-size: 15px;
							font-weight: 500;
							position: absolute;
							top: 3px;
							left: 0;
							pointer-events: none;
							transform-origin: 0% 0%;
							white-space: nowrap;
							transition: transform time(default) ease(inout);

							/** Mobile. */
							@media (max-width: 1500px) {
								font-size: 12px;
								top: 5px;
							}
						}

						.field {
							background-color: transparent;
							color: #565656;
							font-size: 15px;
							font-weight: 500;
							width: 100%;
							padding-bottom: 5px;
							border: none;
							border-bottom: 1px solid #3C3C3C;
							transition: border-color time(default) ease(inout);

							&::placeholder {
								opacity: 0;
								transition: opacity time(default) ease(inout);
							}
						}

						.label {
							color: #565656;
							font-size: 11px;
							font-weight: 500;
							position: relative;
							margin-top: 5px;
							line-height: 1.3;

							&.margin-bottom {
								margin: 0 0 5px;
							}
						}

						textarea {
							height: 60px;
							resize: none;
						}

						&.focus,
						&.not-empty {
							.placeholder {
								transform: translateY(-20px) scale(.8);
							}

							.field {
								border-color: color(blue, normal);

								&::placeholder {
									opacity: 1;
								}
							}
						}

						&.invalid .field {
							border-color: red;
						}

						/** Type select. */
						&[data-type="select"] {
							width: 300px;

							/** Mobile. */
							@media (max-width: 1023px) {
								width: 100%;
							}

							.placeholder {
								opacity: 0;
								transform: translateY(-20px) scale(.8);
							}

							.box {
								position: relative;
								display: flex;
								align-items: center;
								justify-content: space-between;
								width: 100%;
								border-bottom: 1px solid #3C3C3C;
								transition: border-color time(default) ease(inout);
								padding: 2px 0 10px;

								span {
									color: #565656;
									font-size: 15px;
									font-weight: 500;
									white-space: nowrap;
									margin-right: 15px;

									/** Mobile. */
									@media (max-width: 1500px) {
										font-size: 12px;
									}
								}

								svg {
									transform-origin: 50% 50%;
									transition: transform time(default) ease(inout);

									path {
										fill: #3C3C3C;
									}
								}
							}

							.dropdown {
								background-color: #DCDDDD;
								position: absolute;
								top: 100%;
								max-height: 180px;
								overflow: auto;
								right: 0;
								left: 0;
								z-index: 2;
								box-shadow: 0 10px 30px rgba(0, 0, 0, .15);
								opacity: 0;
								transform: translateY(10px);
								pointer-events: none;
								transition: opacity time(default) ease(inout), transform time(slow) ease(inout);

								ul li {
									display: block;
									
									button {
										position: relative;
										display: flex;
										align-items: center;
										justify-content: center;
										width: 100%;
										padding: 15px 10px;
										overflow: hidden;

										&::after {
											content: '';
											background-image: linear-gradient(to right, #B91E92, #FF5E5F);
											position: absolute;
											top: 0;
											right: 0;
											bottom: 0;
											left: 0;
											z-index: 2;
											opacity: 0;
											transition: opacity time(default) ease(inout);
										}
										
										span {
											color: #3C3C3C;
											font-size: 14px;
											font-weight: bold;
											display: inline-block;
											position: relative;
											z-index: 3;
											transition: color time(default) ease(inout);
										}

										&:hover {
											&::after {
												opacity: 1;
											}

											span {
												color: white;
											}
										}
									}
								}
							}

							&.active {
								.box {
									border-color: color(blue, normal);

									svg {
										transform: rotate(180deg);
									}
								}

								.dropdown {
									opacity: 1;
									transform: translateY(0);
									pointer-events: initial;
								}
							}

							&.not-empty {
								.box {
									border-color: color(blue, normal);
								}

								.placeholder {
									opacity: 1;
								}
							}

							&.invalid .box {
								border-color: red;
							}
						}

						&[data-type="options"] {
							display: flex;
							align-items: center;
							padding: 2px 0 10px;
							border-bottom: 1px solid #3C3C3C;
							transition: border-color time(default) ease(inout);

							span {
								color: #565656;
								font-size: 15px;
								font-weight: 500;
								display: inline-block;
								margin-right: 15px;
								flex-shrink: 0;
								white-space: nowrap;

								/** Mobile. */
								@media (max-width: 1500px) {
									font-size: 12px;
								}
							}

							.options ul {
								display: flex;
								align-items: center;

								li {
									&:not(:last-child) {
										margin-right: 20px;
									}

									button {
										position: relative;
										color: #565656;
										font-size: 15px;
										font-weight: normal;
										padding: 0 0 0 15px;

										/** Mobile. */
										@media (max-width: 1500px) {
											font-size: 12px;
										}

										&::before {
											content: '';
											background-color: #E2E2E2;
											position: absolute;
											top: 3px;
											left: 0;
											width: 10px;
											height: 10px;
											border-radius: 50px;

											/** Mobile. */
											@media (max-width: 1500px) {
												top: 1px;
											}
										}

										&::after {
											content: '';
											background-image: linear-gradient(to bottom right, #1D59A2, #3E93D2);
											position: absolute;
											top: 5px;
											left: 2px;
											width: 6px;
											height: 6px;
											border-radius: 50px;
											opacity: 0;
											transition: opacity time(default) ease(inout);

											/** Mobile. */
											@media (max-width: 1500px) {
												top: 3px;
											}
										}
									}

									&.active button::after {
										opacity: 1;
									}
								}
							}

							&.not-empty {
								border-color: color(blue, normal);
							}

							&.invalid {
								border-color: red;
							}
						}

						&[data-type="file"] {
							width: 260px;
							border-bottom: 1px solid #3C3C3C;
							transition: border-color time(default) ease(inout);

							/** Mobile. */
							@media (max-width: 1023px) {
								width: 100%;
							}

							label {
								position: absolute;
								top: -10px;
								left: 0;
								width: 100%;
								height: calc(100% + 10px);
								z-index: 5;
								cursor: pointer;
							}

							.field {
								position: absolute;
								top: 0;
								left: 0;
								opacity: 0;
								pointer-events: none;
							}

							.box {
								position: relative;
								display: flex;
								align-items: center;
								top: -10px;
								margin: 0;
								padding: 0;

								&-left {
									position: relative;
									margin: 0 20px 0 0;
									padding: 0;

									.title {
										color: color(blue, normal);
										font-size: 12px;
										font-weight: 600;
										text-transform: uppercase;
										letter-spacing: .8px;
										margin: 0;
										padding: 0;
									}

									.label {
										color: black;
										font-size: 9px;
										font-weight: 600;
										text-transform: uppercase;
										margin: 4px 0 0;
										padding: 0;
									}
								}
							}

							&.invalid {
								border-color: red;
							}
						}
					}

					.form-table {
						background-color: white;
						width: 100%;
						display: block;
						box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
						border-radius: 15px;
						overflow: hidden;
						
						&--header {
							background-color: #F3F3F5;
							display: flex;
							align-items: center;
							border-bottom: 1px solid fade-out(black, .8);

							.item {
								color: color(blue, normal);
								font-size: 16px;
								font-weight: bold;
								text-transform: uppercase;
								display: flex;
								align-items: center;
								height: 55px;
								padding: 0 30px;

								/** Mobile. */
								@media (max-width: 1500px) {
									padding: 0 20px;
								}
								@media (max-width: 1023px) {
									font-size: 14px;
									padding: 0 10px;
									line-height: 1.3;
								}

								&:not(:last-child) {
									border-right: 1px solid fade-out(black, .8);
								}

								&-title {
									flex-grow: 1;
								}

								&-option {
									width: 115px;
									justify-content: center;

									&:nth-child(3) {

										/** Mobile. */
										@media (max-width: 1023px) {
											border-right: none;
										}
									}

									/** Mobile. */
									@media (max-width: 1500px) {
										width: 90px;
									}
									@media (max-width: 1023px) {
										width: 50px;
										flex-shrink: 0;
									}
								}

								&-details {
									width: 450px;

									/** Mobile. */
									@media (max-width: 1500px) {
										width: 300px;
									}
									@media (max-width: 1023px) {
										display: none;
									}
								}
							}
						}

						&--listing {
							position: relative;
							width: 100%;

							.item {
								display: flex;
								align-items: center;
								transition: background-color time(default) ease(inout);

								/** Mobile. */
								@media (max-width: 1023px) {
									flex-wrap: wrap;
								}

								&:not(:last-child) {
									border-bottom: 1px solid fade-out(black, .8);
								}

								&-label,
								&-option,
								&-field {
									display: flex;
									align-items: center;
									height: 40px;

									/** Mobile. */
									@media (max-width: 1023px) {
										height: 60px;
									}

									&:not(:last-child) {
										border-right: 1px solid fade-out(black, .8);
									}
								}

								&-label {
									color: #989898;
									font-size: 16px;
									font-weight: bold;
									text-transform: uppercase;
									padding: 0 30px;
									flex-grow: 1;
									transition: color time(default) ease(inout);

									/** Mobile. */
									@media (max-width: 1500px) {
										padding: 0 20px;
									}
									@media (max-width: 1023px) {
										width: calc(100% - 100px);
										font-size: 14px;
										padding: 0 10px;
										line-height: 1.3;
									}
								}

								&-option {
									width: 115px;

									&:nth-child(3) {

										/** Mobile. */
										@media (max-width: 1023px) {
											border-right: none;
										}
									}

									/** Mobile. */
									@media (max-width: 1500px) {
										width: 90px;
									}
									@media (max-width: 1023px) {
										width: 50px;
										flex-shrink: 0;
									}

									button {
										position: relative;
										width: 100%;
										height: 100%;
										cursor: pointer;

										&::before {
											content: '';
											background-color: #E2E2E2;
											position: absolute;
											top: 50%;
											left: 50%;
											width: 10px;
											height: 10px;
											transform: translate(-50%, -50%);
											border-radius: 50px;
										}

										&::after {
											content: '';
											background-image: linear-gradient(to bottom right, #1D59A2, #3E93D2);
											position: absolute;
											top: 50%;
											left: 50%;
											width: 6px;
											height: 6px;
											border-radius: 50px;
											transform: translate(-50%, -50%);
											opacity: 0;
											transition: opacity time(default) ease(inout);
										}
									}

									&:hover button::after,
									&.active button::after {
										opacity: 1;
									}
								}

								&-field {
									width: 450px;

									/** Mobile. */
									@media (max-width: 1500px) {
										width: 300px;
									}
									@media (max-width: 1023px) {
										border-top: 1px solid fade-out(black, .8);
										width: 100%;
									}

									.field {
										background-color: transparent;
										color: color(grey);
										font-size: 15px;
										font-weight: normal;
										width: 100%;
										height: 100%;
										padding: 0 15px;
										border: none;

										/** Mobile. */
										@media (max-width: 1023px) {
											font-size: 14px;
											padding: 0 10px;
										}										
									}
								}

								&.invalid .item-label {
									color: red;
								}

								/** Desktop. */
								@media (min-width: 1024px) {
									&:hover {
										background-color: #F3F3F5;
									}
								}

								/** Mobile. */
								@media (max-width: 1023px) {
									&:nth-child(even) {
										background-color: #F3F3F5;
									}
								}
							}
						}
					}
				}

				.form-submit {
					display: flex;
					justify-content: center;

					button {
						background-size: 200% 100%;
						background-position: left center;
						background-image: linear-gradient(to right, #174E98, #49A7E3, #174E98);
						display: flex;
						align-items: center;
						justify-content: center;
						width: 180px;
						height: 50px;
						margin: 30px auto 0;
						border-radius: 50px;
						transition: background-position time(default) ease(inout);

						&:hover {
							background-position: right center;
						}

						span {
							color: white;
							font-size: 16px;
							font-weight: bold;
							text-transform: uppercase;
						}
					}
				}
			}

			.form-data .form-row {
				&:nth-child(1) .field-group {
					&:nth-child(2) {
						width: 200px;
						flex-grow: unset;

						/** Mobile. */
						@media (max-width: 1023px) {
							width: 100%;
						}
					}

					&:nth-child(3) {
						width: 350px;
						flex-grow: unset;

						/** Mobile. */
						@media (max-width: 1023px) {
							width: 100%;
						}
					}
				}

				&:nth-child(2) .field-group {
					&:nth-child(1) {
						width: 55px;
						flex-grow: unset;

						/** Mobile. */
						@media (max-width: 1023px) {
							width: 100%;
						}
					}

					&:nth-child(2),
					&:nth-child(3) {
						width: 100px;
						flex-grow: unset;

						/** Mobile. */
						@media (max-width: 1023px) {
							width: 100%;
						}
					}

					&:nth-child(4),
					&:nth-child(5) {
						width: auto;
						flex-grow: unset;

						/** Mobile. */
						@media (max-width: 1023px) {
							width: 100%;
						}
					}
				}
			}

			.form-foods .form-row .field-group {
				width: calc(50% - 40px);
				flex-grow: unset;

				/** Mobile. */
				@media (max-width: 1200px) {
					width: 100%;
				}

				&:not(:last-child) {
					margin-right: 80px;
				}
			}
		}
	}
}
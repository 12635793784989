#page-search {
	position: relative;
	width: 100%;
	padding: 60px 60px 0;
	overflow: hidden;

	/** Mobile. */
	@media (max-width: 1023px) {
		padding: 60px 0 0;
	}

	/** Section page hero. */
	.section-page-hero .background {
		background-image: linear-gradient(to right, #B91E92, #FF5E5F);
	}

	/** Section search. */
	.section-search {
		padding: 70px 0;

		.message p {
			color: black;
			font-size: 16px;
			font-weight: normal;
			line-height: 1.7;
			text-align: center;
			margin-bottom: 50px;
		}

		.flex-wrapper {
			display: flex;
		}
	}
}
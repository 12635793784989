#main-header {
	background-color: white;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	padding: 0 60px;
	z-index: 50;

	/** Mobile. */
	@media (max-width: 1200px) {
		padding: 0 25px;
	}

	.flex-wrapper {
		display: flex;
		height: 60px;
		align-items: center;
		justify-content: space-between;

		.logo {
			position: relative;
			bottom: -3px;

			a {
				display: inline-block;
			}
		}

		.right {
			display: flex;
			align-items: center;

			/** Mobile. */
			@media (max-width: 1200px) {
				display: none;
			}

			&-account a {
				display: flex;
				align-items: center;

				svg path {
					fill: color(grey);
					transition: fill time(default) ease(inout);
				}

				span {
					color: color(grey);
					font-size: 10px;
					font-weight: bold;
					text-transform: uppercase;
					letter-spacing: .8px;
					display: inline-block;
					position: relative;
					bottom: -1px;
					margin-left: 10px;
					transition: color time(default) ease(inout);
				}

				&:hover {
					svg path {
						fill: color(blue, light);
					}

					span {
						color: color(blue, light);
					}
				}
			}

			&-member {
				position: relative;
				display: flex;
				align-items: center;

				.member-link {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 5;
				}

				.member-image {
					width: 41px;
					height: 41px;
					border-radius: 50%;
					overflow: hidden;
					flex-shrink: 0;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.member-content {
					width: 130px;
					margin-left: 10px;

					&--title {
						color: #404040;
						font-size: 10px;
						font-weight: bold;
						text-transform: uppercase;
						letter-spacing: .8px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					&--plan {
						color: #989898;
						font-size: 10px;
						font-weight: bold;
						text-transform: uppercase;
						margin-top: 3px;
					}
				}
			}

			&-languages {
				margin-left: 60px;

				a {
					&:not(:first-child) {
						margin-left: 20px;
					}
				}
			}
		}
	}

	/** Style portal. */
	&.style-portal {
		padding: 0 60px 0 25px;
	}
}

#main-social {
	background-color: white;
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 50;
	width: 60px;

	/** Mobile. */
	@media (max-width: 1200px) {
		display: none;
	}

	ul {
		width: 100%;

		li {
			display: block;
			text-align: center;

			&:not(:last-child) {
				margin-bottom: 20px;
			}
			
			a {
				position: relative;
				display: inline-block;
				
				svg path {
					fill: color(grey);
				}

				span {
					color: color(grey);
					font-size: 10px;
					font-weight: bold;
					text-transform: uppercase;
					position: absolute;
					top: calc(50% - 1px);
					left: calc(50% + 20px);
					opacity: 0;
					transform: translate(10px, -50%);
					transition: opacity time(fast) ease(inout), transform time(default) ease(inout);
				}

				&:hover span {
					opacity: 1;
					transform: translate(0, -50%);
				}
			}
		}
	}

	/** Style portal. */
	&.style-portal {
		background-color: transparent;
		top: 20px;
		right: 280px;
		bottom: unset;
		left: unset;

		ul {
			display: flex;

			li {
				height: 20px;
				display: flex;
				align-items: center;

				&:not(:last-child) {
					margin-right: 30px;
				}

				span {
					top: calc(50% + 20px);
					left: 50%;
					transform: translate(-50%, calc(-50% + 5px));
				}

				&:hover span {
					transform: translate(-50%, -50%);
				}
			}
		}
	}
}

#main-menu-toggler {
	background-color: white;
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 50;
	width: 60px;

	/** Mobile. */
	@media (max-width: 1200px) {
		display: none;
	}

	button {
		position: relative;
		width: 55px;
		height: 40px;
		left: -38px;
		overflow: hidden;
		z-index: 5;

		span {
			position: absolute;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				background-color: black !important;
				width: 23px;
				height: 2px;
				right: 0;
				opacity: 1 !important;
				transform-origin: 0 50%;
				transition: transform time(fast) ease(inout), right time(fast) ease(inout);
			}

			&:nth-child(1) {
				top: calc(50% - 6px);
				transform: scaleX(.6);
			}

			&:nth-child(2) {
				top: 50%;
				transform: scaleX(.8);
			}

			&:nth-child(3) {
				top: calc(50% + 6px);
				transform: scaleX(.7);
			}

			&:nth-child(4) {
				top: calc(50% - 6px);
				transform: scaleX(0);
			}

			&:nth-child(5) {
				top: 50%;
				transform: scaleX(0);
			}

			&:nth-child(6) {
				top: calc(50% + 6px);
				transform: scaleX(0);
			}
			
			&:nth-child(7) {
				color: black;
				font-size: 12px;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: .8px;
				top: 15px;
				left: -10px;
				transform: rotate(90deg);
			}
		}

		&:hover span {
			&:nth-child(1) {
				transform: scaleX(1);
				right: -24px;
				transition-delay: 0s, .15s;
			}

			&:nth-child(2) {
				transform: scaleX(1);
				right: -24px;
				transition-delay: .1s, .25s;
			}

			&:nth-child(3) {
				transform: scaleX(1);
				right: -24px;
				transition-delay: .2s, .35s;
			}

			&:nth-child(4) {
				transform: scale(1);
				transition-delay: .3s;
			}

			&:nth-child(5) {
				transform: scale(.8);
				transition-delay: .4s;
			}

			&:nth-child(6) {
				transform: scale(1);
				transition-delay: .5s;
			}
		}
	}
}
#page-portal-chat {
	
	/** Section chat. */
	.section-chat {
		.headline {
			background-color: #F4F4F4;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 65px;

			&-image {
				position: relative;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			&-title {
				color: color(blue, normal);
				font-size: 16px;
				font-weight: bold;
				margin-left: 15px;
			}
		}

		.chat {
			background-color: #f9f9fa;
			position: relative;
			width: 100%;
			height: auto;

			.messages {
				position: relative;
				padding: 35px 80px 135px;

				/** Mobile. */
				@media (max-width: 1200px) {
					padding: 35px 20px 135px;
				}
				
				.message {
					width: 70%;
					display: flex;
					align-items: flex-start;

					/** Mobile. */
					@media (max-width: 1200px) {
						width: 85%;
					}

					&:not(:last-child) {
						margin-bottom: 25px;
					}

					&-image {
						position: relative;
						width: 60px;
						height: 60px;
						border-radius: 50%;
						flex-shrink: 0;
						overflow: hidden;

						/** Mobile. */
						@media (max-width: 1200px) {
							width: 35px;
							height: 35px;
						}

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					&-content {
						flex-grow: 1;

						&--title {
							display: flex;
							align-items: center;

							/** Mobile. */
							@media (max-width: 1200px) {
								flex-direction: column;
								align-items: flex-start;
							}

							span {
								&:nth-child(1) {
									color: color(blue, normal);
									font-size: 16px;
									font-weight: bold;
									margin-right: 10px;

									/** Mobile. */
									@media (max-width: 1200px) {
										font-size: 14px;
										margin-right: 0;
										margin-bottom: 5px;
									}
								}

								&:nth-child(2) {
									color: #989898;
									font-size: 15px;
									font-weight: normal;

									/** Mobile. */
									@media (max-width: 1200px) {
										font-size: 13px;
									}
								}
							}
						}

						&--description {
							width: auto;
							display: inline-block;
							margin-top: 10px;
							padding: 20px 30px;

							/** Mobile. */
							@media (max-width: 1200px) {
								padding: 15px 20px;
							}

							p {
								color: white;
								font-size: 16px;
								font-weight: normal;
								line-height: 1.4;

								/** Mobile. */
								@media (max-width: 1200px) {
									font-size: 14px;
								}
							}

							.archive {
								position: relative;
								display: flex;
								align-items: center;
								margin-top: 20px;

								&-download {
									position: absolute;
									top: 50%;
									left: -65px;
									transform: translateY(-50%);

									svg path {
										fill: #989898;
										transition: fill time(default) ease(inout);
									}

									&:hover svg path {
										fill: color(blue, normal);
									}
								}

								&-image {
									width: 100%;

									img {
										max-width: 100%;
										max-height: 300px;
									}
								}

								&-clip {
									margin-right: 15px;

									svg path {
										fill: white;
									}
								}

								&-left {
									position: relative;
									flex-shrink: 0;

									&--icon {
										position: relative;
										z-index: 1;

										svg path {
											fill: white;
										}
									}

									&--type {
										color: #14739B;
										font-size: 11px;
										font-weight: bold;
										text-transform: uppercase;
										position: absolute;
										top: 23px;
										left: calc(50% - 3px);
										transform: translateX(-50%);
										z-index: 2;
									}
								}

								&-right {
									max-width: 200px;

									&--title {
										color: white;
										font-size: 12px;
										font-weight: normal;
										margin-left: 10px;
									}
								}
							}
						}
					}

					/** Variations. */
					&.message-nutritionist {
						.message-image {
							order: 1;
						}

						.message-content {
							margin-left: 20px;
							order: 2;

							&--description {
								background-color: color(blue, normal);
								border-radius: 0 70px 70px 70px;

								/** Mobile. */
								@media (max-width: 1200px) {
									border-radius: 0 40px 40px 40px;
								}

								&.archive {
									background-color: #14739B;
								}
							}
						}
					}

					&.message-user {
						margin-left: auto;
						margin-right: 0;

						.message-image {
							order: 2;
						}

						.message-content {
							text-align: right;
							margin-right: 20px;
							order: 1;

							&--title {
								align-items: flex-end;
								justify-content: flex-end;
							}

							&--date {
								text-align: right;
							}

							&--description {
								background-color: #84BED1;
								border-radius: 70px 0 70px 70px;
								text-align: left;

								/** Mobile. */
								@media (max-width: 1200px) {
									border-radius: 40px 0 40px 40px;
								}

								&.archive {
									background-color: #14739B;
								}
							}
						}
					}
				}
			}

			.write {
				background-color: #F4F4F4;
				position: fixed;
				right: 60px;
				bottom: 60px;
				left: 230px;
				height: 100px;
				z-index: 2;

				/** Mobile. */
				@media (max-width: 1200px) {
					position: fixed;
					right: 0;
					bottom: 0;
					left: 0;
				}

				.form {
					position: relative;
					display: flex;
					width: 100%;
					height: 100%;

					.field-group {
						flex-grow: 1;
						height: 100%;

						.field {
							background-color: transparent;
							color: #989898;
							font-size: 15px;
							font-weight: normal;
							border: none;
							width: 100%;
							height: 100%;
							padding: 41px 30px 10px 90px;
							resize: none;

							/** Mobile. */
							@media (max-width: 1200px) {
								padding: 41px 15px 10px 10px;
							}

							&::placeholder {
								color: #989898;
							}
						}
					}

					.form-right {
						position: relative;
						height: 100%;
						display: flex;
						align-items: center;
						padding: 0 45px;

						/** Mobile. */
						@media (max-width: 1200px) {
							padding: 0 20px;
						}

						&::before {
							content: '';
							background-color: #AAAAAA;
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							width: 1px;
							height: 50px;
						}

						.form-preview-image {
							width: 55px;
							height: 55px;
							margin-right: 35px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.form-upload {
							margin-right: 35px;

							.message {
								background-color: black;
								color: white;
								font-size: 14px;
								font-weight: normal;
								text-transform: uppercase;
								white-space: nowrap;
								padding: 10px 25px;
								position: absolute;
								border-radius: 5px;
								bottom: 80px;
								right: 50px;
								opacity: 0;
								transition: opacity time(default) ease(inout);

								&.active {
									opacity: 1;
								}
							}

							input {
								position: absolute;
								top: 0;
								left: 0;
								opacity: 0;
								visibility: hidden;
								pointer-events: none
							}

							label {
								position: relative;
								cursor: pointer;
								
								svg path {
									fill: color(blue, normal);
									transition: fill time(default) ease(inout);
								}

								&:hover svg path {
									fill: #989898;
								}
							}
						}

						.form-submit {
							svg path {
								fill: color(blue, normal);
								transition: fill time(default) ease(inout);
							}

							&:hover svg path {
								fill: #989898;
							}
						}
					}
				}
			}
		}
	}
}
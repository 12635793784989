#page-portal-home {

	/** Section portal. */
	.section-portal {
		padding: 85px 0 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 50px 0;
		}

		.container {
			padding: 0 50px 0 0;

			/** Mobile. */
			@media (max-width: 1023px) {
				padding: 0 25px;
			}
		}

		.flex-wrapper {
			display: flex;
			align-items: center;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
			}

			.image {
				flex-shrink: 0;

				/** Mobile. */
				@media (max-width: 1023px) {
					position: relative;
					width: 100%;
					left: -10px;

					img {
						width: 100%;
					}
				}
			}

			.content {
				flex-grow: 1;
				margin-left: 20px;

				/** Mobile. */
				@media (max-width: 1023px) {
					flex-grow: unset;
					width: 100%;
					margin: 40px 0 0;
				}

				&-title {
					color: #565656;
					font-size: 15px;
					font-weight: bold;
					display: inline-block;
					margin-bottom: 20px;
				}

				&-description {
					p {
						color: #565656;
						font-size: 15px;
						font-weight: normal;
						line-height: 1.7;
					}
				}
			}
		}
	}
}
#page-user-access {
	position: relative;
	width: 100%;
	height: calc(100vh - 60px);

	/** Mobile. */
	@media (max-width: 1023px) {
		height: 100vh;
	}

	/** General section. */
	.section {
		.background {
			position: absolute;
			top: 60px;
			right: 60px;
			bottom: 0;
			left: 60px;
			z-index: 1;

			/** Mobile. */
			@media (max-width: 1023px) {
				right: 0;
				left: 0;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.wrapper {
			display: flex;
			align-items: center;
			position: absolute;
			top: 60px;
			right: 60px;
			bottom: 0;
			left: 60px;
			z-index: 2;

			/** Mobile. */
			@media (max-width: 1023px) {
				right: 0;
				left: 0;
			}

			&-content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				
				.logo {
					position: relative;
					margin-bottom: 40px;

					/** Mobile. */
					@media (max-width: 1023px) {
						display: none;
					}

					a {
						display: inline-block;
					}
				}

				.block {
					position: relative;
					background-color: white;
					padding: 55px 70px 60px;

					/** Mobile. */
					@media (max-width: 1023px) {
						padding: 30px 15px;
					}

					&-title {
						color: color(blue, normal);
						font-size: 16px;
						font-weight: bold;
						text-transform: uppercase;
						text-align: center;
						margin: 0 0 30px;
						padding: 0;

						/** Mobile. */
						@media (max-width: 1023px) {
							margin: 0 0 20px;
						}
					}

					&-message {
						background-color: fade-out(black, .3);
						display: flex;
						align-items: center;
						justify-content: center;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 5;
						opacity: 0;
						visibility: hidden;
						transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

						/** Mobile. */
						@media (max-width: 1023px) {
							position: fixed;
						}

						&.active {
							opacity: 1;
							visibility: visible;
						}

						span {
							color: white;
							font-size: 16px;
							font-weight: bold;
							margin: 0;
							padding: 0;

							/** Mobile. */
							@media (max-width: 1023px) {
								padding: 0;
								font-size: 12px;
							}
						}
					}

					&-form .form {
						.form-row {
							display: flex;
							align-items: center;
							justify-content: space-between;

							/** Mobile. */
							@media (max-width: 1023px) {
								flex-wrap: wrap;
							}

							&:not(:last-child) {
								margin-bottom: 30px;
							}

							.field-group {
								flex-grow: 1;
								position: relative;

								/** Mobile. */
								@media (max-width: 1023px) {
									flex-grow: unset;
									width: 100%;
								}

								&:not(:last-child) {
									margin-right: 40px;

									/** Mobile. */
									@media (max-width: 1023px) {
										margin-right: 0;
										margin-bottom: 30px;
									}
								}
								
								.placeholder {
									color: color(dark);
									font-size: 15px;
									font-weight: 500;
									position: absolute;
									top: 3px;
									left: 0;
									pointer-events: none;
									transform-origin: 0% 0%;
									transition: transform time(default) ease(inout);
								}

								.field {
									background-color: transparent;
									color: color(dark);
									font-size: 15px;
									font-weight: 500;
									width: 100%;
									padding-bottom: 5px;
									border: none;
									border-bottom: 1px solid #3C3C3C;
									transition: border-color time(default) ease(inout);
								}

								textarea {
									height: 60px;
									resize: none;
								}

								/** Type options. */
								&[data-type="options"] {
									display: flex;
									align-items: center;
									justify-content: center;
									padding: 2px 0 10px;

									.options ul {
										display: flex;
										align-items: center;

										li {
											&:not(:last-child) {
												margin-right: 20px;
											}

											button {
												position: relative;
												color: #565656;
												font-size: 10px;
												font-weight: normal;
												padding: 0 0 0 15px;
												transition: color time(default) ease(inout);

												/** Mobile. */
												@media (max-width: 1500px) {
													font-size: 12px;
												}

												&::before {
													content: '';
													background-color: #E2E2E2;
													position: absolute;
													top: 0px;
													left: 0;
													width: 10px;
													height: 10px;
													border-radius: 50px;

													/** Mobile. */
													@media (max-width: 1500px) {
														top: 1px;
													}
												}

												&::after {
													content: '';
													background-image: linear-gradient(to bottom right, #1D59A2, #3E93D2);
													position: absolute;
													top: 2px;
													left: 2px;
													width: 6px;
													height: 6px;
													border-radius: 50px;
													opacity: 0;
													transition: opacity time(default) ease(inout);

													/** Mobile. */
													@media (max-width: 1500px) {
														top: 3px;
													}
												}

												a {
													color: #565656;
													font-weight: bold;
												}

												span {
													font-size: 12px;
													text-transform: uppercase;
												}
											}

											&.active button::after {
												opacity: 1;
											}
										}
									}

									&.not-empty {
										border-color: color(blue, normal);
									}

									&.invalid ul li button {
										color: red;
									}
								}

								input[type="password"] {
									padding-right: 40px;
								}

								.space-error {
									position: absolute;
									top: 100%;
									right: 0;
									left: 0;
									z-index: 1;
									color: red;
									font-size: 12px;
									font-weight: normal;
									text-align: center;
									display: block;
									line-height: 1.2;
									height: 0;
									overflow: hidden;
									margin: 0;

									/** Mobile. */
									@media (max-width: 1023px) {
										position: relative;
										top: unset;
										left: unset;
										right: unset;
										text-align: left;
									}
								}

								.show-password {
									position: absolute;
									top: 0;
									right: 0;
									z-index: 5;

									svg path {
										transition-property: stroke, stroke-opacity;
										transition-duration: time(default);
										transition-timing-function: ease(inout);
									}
								}

								&.show-pass .show-password svg path {
									stroke: color(blue, normal);
									stroke-opacity: 1;
								}

								&.focus,
								&.not-empty {
									.placeholder {
										transform: translateY(-20px) scale(.8);
									}

									.field {
										border-color: color(blue, normal);
									}
								}

								&.invalid .field {
									border-color: red;
								}

								&.space-error .space-error {
									height: auto;
									margin-top: 10px;
								}
							}

							&.form-row--full {
								flex-wrap: wrap;

								.field-group {
									flex-grow: unset;
									width: 100%;
								}
							}
						}

						.form-message {
							position: relative;
							text-align: center;

							p {
								line-height: 1.8;
							}
							
							a {
								color: #565656;
								font-size: 15px;
								font-weight: normal;
								transition: color time(default) ease(inout);

								&:hover {
									color: black;
								}
							}
						}

						.form-canvas {
							position: relative;
							display: flex;
							flex-direction: column;
							align-items: center;

							canvas {
								border: 1px solid black;
								transition: border-color time(default) ease(inout);
							}

							span {
								color: black;
								font-size: 16px;
								font-weight: normal;
								text-transform: uppercase;
								letter-spacing: 1px;
								position: absolute;
								top: calc(50% - 15px);
								left: 50%;
								transform: translate(-50%, -50%);
								z-index: 1;
								pointer-events: none;
								transition: opacity time(default) ease(inout);
							}

							button {
								color: color(blue, normal);
								font-size: 16px;
								font-weight: bold;
								text-transform: uppercase;
								margin-top: 15px;
							}

							&.invalid canvas {
								border-color: red;
							}

							&.active span {
								opacity: 0;
							}
						}

						.form-submit {
							position: relative;
							display: flex;
							justify-content: center;
							margin-top: 40px;

							button {
								background-image: linear-gradient(to right, #174E98, #49A7E3);
								display: flex;
								align-items: center;
								justify-content: center;
								width: 220px;
								height: 50px;
								border-radius: 50px;

								span {
									color: white;
									font-size: 16px;
									font-weight: bold;
									text-transform: uppercase;
									margin-right: 13px;
								}

								svg path {
									fill: white;
								}
							}
						}
					}
				}
			}

			&-register {
				display: block;
				overflow: auto;
				padding: 60px 0;
			}
		}

		/** Variations. */
		&.section-login .wrapper .wrapper-content .block {
			width: 450px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		&.section-register .wrapper {

			/** Mobile. */
			@media (max-width: 1023px) {
				padding: 75px 0 40px;
				display: block;
				overflow: auto;
			}

			.wrapper-content .block {
				width: 1100px;

				/** Mobile. */
				@media (max-width: 1023px) {
					width: 100%;
				}
			}
		}
	}
}
#page-home {
	position: relative;
	width: 100%;
	padding: 60px 60px 0;
	overflow: hidden;

	/** Section hero. */
	.section-hero {
		position: relative;
		width: 100%;
		height: calc(100vh - 120px);
		overflow: hidden;

		.background {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;

			&-left,
			&-right {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 50%;
				overflow: hidden;

				&--text {
					font-size: 27px;
					font-weight: 300;
					position: absolute;
					top: calc(50% - 150px);
					margin: 0;
					padding: 0;

					.line {
						display: flex;
						flex-wrap: wrap;

						.word {
							display: flex;
							height: 33px;

							&:not(:last-child) {
								margin-right: 8px;
							}

							s {
								position: relative;
								opacity: 0;
								transform: translateY(100%) rotate(30deg);
								transition: transform time(default) ease(inout), opacity time(default) ease(inout);
							}
						}
					}
				}
			}

			&-left {
				background-color: #F9F9FA;
				left: 0;

				&--text {
					color: #74C6E0;
					right: 0;
					transform: translate(50%, -50%);
				}
			}

			&-right {
				background-image: linear-gradient(to right, #49A7E3, #174E99);
				right: 0;

				&--text {
					color: white;
					left: 0;
					transform: translate(-50%, -50%);
				}
			}
		}

		.vectors {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 2;
			overflow: hidden;

			&-icon {
				position: absolute;
				bottom: -10px;
				left: 50px;
				opacity: 0;
				transition: opacity time(slow) ease(inout);

				img {
					user-select: none;
					pointer-events: none;
				}
			}

			&-logo--typography {
				position: absolute;
				top: calc(50% + 100px);
				left: 50%;
				transform: translate(-50%, -50%);

				svg {
					overflow: visible;

					path {
						fill: transparent;
						stroke: #71C5E0;
						stroke-width: 2px;
						stroke-dasharray: 750;
						stroke-dashoffset: 750;
						transition: stroke-dashoffset 2s ease(inout), stroke time(default) ease(inout), fill time(slow) ease(inout);
					}
				}
			}

			&-logo--icon {
				position: absolute;
				bottom: -100px;
				right: -80px;
				opacity: 0;
				transform-origin: 50% 50%;
				transform: scale(0.9);
				transition: opacity 2s ease(inout), transform 2s ease(inout);

				svg path {
					fill: #84BED1;
				}
			}
		}

		.init-progress {
			position: absolute;
			top: calc(50% + 200px);
			left: 185px;
			transform: translateY(-50%);
			z-index: 3;

			/** Mobile. */
			@media (max-width: 1800px) {
				top: calc(50% + 145px);
				left: 187px;
				transform-origin: 0% 0%;
				transform: scale(0.6) translateY(-50%);
			}

			&::before {
				content: '';
				position: absolute;
				top: -14px;
				left: -1px;
				width: 5px;
				height: 5px;
				border: 5px solid #71C5E0;
				border-radius: 50%;
				opacity: 0;
				transform-origin: 50% 50%;
				transform: scale(0);
				transition: opacity time(default) ease(inout), transform time(default) ease(inout);
			}

			&::after {
				content: '';
				position: absolute;
				top: -20px;
				left: -7px;
				width: 17px;
				height: 17px;
				border: 5px solid #71C5E0;
				border-radius: 50%;
				opacity: 0;
				transform-origin: 50% 50%;
				transform: scale(0);
				transition: opacity time(default) ease(inout), transform time(default) ease(inout);
			}

			svg path {
				fill: transparent;
				stroke: #4AC4EB;
				stroke-width: 4px;
				stroke-dasharray: 640;
				stroke-dashoffset: 640;
				transition: stroke-dashoffset time(fast) linear;
			}
		}

		.progress {
			display: flex;
			align-items: flex-start;
			position: absolute;
			top: calc(50% + 8px);
			left: 399px;
			transform: translateY(-50%);
			height: 355px;
			z-index: 3;

			/** Mobile. */
			@media (max-width: 1800px) {
				top: calc(50% - 112px);
				left: 315px;
				transform-origin: 0% 100%;
				transform: scale(0.6) translateY(-50%);
			}

			&::after {
				content: '';
				background-image: linear-gradient(to top, #4AC4EB, white);
				position: absolute;
				bottom: 108px;
				right: 65px;
				width: 4px;
				height: 300px;
				transform-origin: 50% 100%;
				transform: scaleY(0);
				transition: transform .2s linear;
			}

			.item {
				position: relative;

				svg path {
					fill: transparent;
					stroke: #4AC4EB;
					stroke-width: 4px;
					stroke-dasharray: 640;
					stroke-dashoffset: 640;
					transition: stroke-dashoffset time(fast) linear;
				}

				&:nth-child(even) {
					bottom: -92px;
				}

				&:nth-child(1) {
					left: 0;
				}
				&:nth-child(2) {
					left: -12px;
				}
				&:nth-child(3) {
					left: -23px;
				}
				&:nth-child(4) {
					left: -35px;
				}
				&:nth-child(5) {
					left: -46px;
				}
				&:nth-child(6) {
					left: -58px;
				}
			}
		}

		.wrapper {
			display: flex;
			position: absolute;
			top: calc(50% - 60px);
			transform: translateY(-50%);
			left: 438px;
			z-index: 4;

			/** Mobile. */
			@media (max-width: 1800px) {
				top: calc(50% - 10px);
				left: 337px;
			}

			.item {
				position: relative;
				width: 140px;
				height: 140px;

				/** Mobile. */
				@media (max-width: 1800px) {
					width: 84px;
					height: 84px;
				}

				&:not(:last-child) {
					margin-right: 68px;

					/** Mobile. */
					@media (max-width: 1800px) {
						margin-right: 41px;
					}
				}

				&-icon {
					background-color: white;
					position: relative;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					border: 1px solid #CFCFCF;
					border-radius: 50%;
					box-shadow: 10px 10px 30px rgba(0, 0, 0, .3);
					z-index: 2;
					opacity: 0;
					transition: opacity time(slow) ease(inout);

					svg {
						overflow: visible;

						/** Mobile. */
						@media (max-width: 1800px) {
							transform-origin: 50% 50%;
							transform: scale(0.6);
						}
					}

					span {
						background-image: linear-gradient(to right, #45A0DE, #2260A7);
						display: inline-block;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;

						/** Mobile. */
						@media (max-width: 1800px) {
							transform-origin: 50% 50%;
							transform: scale(0.6);
						}
					}
				}

				&-arrow {
					position: absolute;
					z-index: 1;

					/** Mobile. */
					@media (max-width: 1800px) {
						transform-origin: 0% 0%;
						transform: scale(.6);
					}

					svg {
						overflow: visible;

						path {
							fill: transparent;
							stroke: #4AC4EB;
							stroke-width: 2px;
							stroke-dasharray: 530;
							stroke-dashoffset: 530;
							transition: stroke-dashoffset 2s ease(inout), stroke time(default) ease(inout), fill time(default) ease(inout);
						}
					}
				}

				&-description {
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					width: 170px;
					text-align: center;
					padding: 0 5px;
					opacity: 0;
					transition: opacity time(slow) ease(inout);

					/** Mobile. */
					@media (max-width: 1800px) {
						width: 130px;
					}

					p {
						color: white;
						font-size: 15px;
						font-weight: normal;
						font-style: italic;
						line-height: 1.4;
						margin: 0;
						padding: 0;

						/** Mobile. */
						@media (max-width: 1800px) {
							font-size: 13px;
						}
					}
				}

				&:nth-child(1) .item-icon span {
					font-size: 40px;
					height: 47px;
					font-weight: bold;
					user-select: none;
				}
				&:nth-child(5) .item-icon span {
					font-size: 30px;
					font-weight: bold;
					margin: 0 6px;
					user-select: none;

					/** Mobile. */
					@media (max-width: 1800px) {
						margin: 0 -7px;
					}
				}

				&:nth-child(odd) {
					.item-arrow {
						top: 70px;
						left: -37px;

						/** Mobile. */
						@media (max-width: 1800px) {
							top: 42px;
							left: -21px;
						}
					}

					.item-description {
						top: calc(100% + 75px);

						/** Mobile. */
						@media (max-width: 1800px) {
							top: calc(100% + 45px);
						}
					}
				}

				&:nth-child(even) {
					bottom: -130px;

					/** Mobile. */
					@media (max-width: 1800px) {
						bottom: -75px;
					}

					.item-arrow {
						bottom: 70px;
						left: -35px;

						/** Mobile. */
						@media (max-width: 1800px) {
							transform-origin: 0% 100%;
							bottom: 42px;
							left: -20px;
						}
					}

					.item-description {
						bottom: calc(100% + 75px);

						/** Mobile. */
						@media (max-width: 1800px) {
							bottom: calc(100% + 45px);
						}
					}
				}

				&:nth-child(5) {
					.item-arrow {
						left: -35px;

						/** Mobile. */
						@media (max-width: 1800px) {
							left: -19px;
						}
					}
				}

				&:nth-child(6) {
					.item-arrow {
						left: -32px;

						/** Mobile. */
						@media (max-width: 1800px) {
							left: -20px;
						}
					}
				}
			}
		}

		.logo {
			position: absolute;
			top: calc(50% - 230px);
			left: 1564px;
			transform: translateY(-50%);
			z-index: 2;

			/** Mobile. */
			@media (max-width: 1800px) {
				top: calc(50% - 150px);
				left: 978px;
				transform-origin: 50% 50%;
				transform: scale(.6) translateY(-50%);
			}

			&::before {
				content: '';
				position: absolute;
				top: calc(50% - 5px);
				left: 50%;
				width: 5px;
				height: 5px;
				border: 5px solid white;
				border-radius: 50%;
				opacity: 0;
				transform-origin: 0% 0%;
				transform: scale(0) translate(-50%, -50%);
				transition: opacity time(default) ease(inout), transform time(default) ease(inout);
				z-index: 2;
			}

			&::after {
				content: '';
				position: absolute;
				top: calc(50% - 5px);
				left: 50%;
				width: 17px;
				height: 17px;
				border: 5px solid white;
				border-radius: 50%;
				opacity: 0;
				transform-origin: 0% 0%;
				transform: scale(0) translate(-50%, -50%);
				transition: opacity time(default) ease(inout), transform time(default) ease(inout);
				z-index: 2;
			}

			svg {
				opacity: 0;
				transform-origin: 50% 50%;
				transform: scale(0);
				transition: opacity time(default) ease(inout), transform time(default) ease(inout);
				z-index: 1;

				path {
					fill: #0AC1FC;
				}
			}
		}

		.scroll-down {
		  background-color: fade-out(white, .7);
		  position: absolute;
		  bottom: 0;
		  left: calc(50% - 1px);
		  width: 2px;
		  height: 60px;
		  z-index: 3;
		  transform: translateY(100%);
		  transition: transform time(default) ease(inout);

		  span {
		  	color: white;
		  	font-size: 12px;
		  	font-weight: bold;
		  	text-transform: uppercase;
		  	letter-spacing: 1px;
		  	white-space: nowrap;
		  	position: absolute;
		  	top: -25px;
		  	left: 50%;
		  	transform: translateX(-50%);
		  	opacity: 0;
		  	transition: opacity time(default) ease(inout);
		  }

		  &--line {
		    background-color: white;
		    position: absolute;
		    top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
		    z-index: 1;
		  }
		}

		&.animate-1 {
			.background {
				&-left,
				&-right {
					&--text .line .word s {

						/** @each: Delay. */
						@for $i from 1 to 30 {
							&[index="#{$i}"] {
								transition-delay: #{.05s * $i};
								opacity: 1;
								transform: translateY(0) rotate(0deg);
							}
						}
					}
				}
			}

			.vectors {
				&-icon {
					opacity: 1;
					transition-delay: 2s;
				}

				&-logo--typography svg path {
					fill: #71C5E0;
					stroke: transparent;
					stroke-dashoffset: 0;
					transition-delay: 1s, 3s, 3s;
				}

				&-logo--icon {
					opacity: .1;
					transform: scale(1);
					transition-delay: 2s;
				}
			}
		}

		&.animate-2 {
			.background {
				&-left {
					will-change: transform;
					transition: transform time(slow) ease(inout);
					transition-delay: .65s;
					transform: translateX(-100%);
				}

				&-right {
					will-change: width;
					width: 100%;
					transition: width time(slow) ease(inout);
					transition-delay: .65s;
				}
			}

			.vectors {
				will-change: transform;
				transform: translateX(-50%);
				transition: transform time(slow) ease(inout);
				transition-delay: .5s;

				&-icon {
					transition: opacity time(default) ease(inout);
					opacity: 0;
					transition-delay: 0s;
				}

				&-logo--icon {
					transition: opacity time(default) ease(inout);
					opacity: 0;
					transition-delay: 0s;
				}
			}

			.init-progress {
				&::before,
				&::after {
					opacity: 1;
					transform: scale(1);
					transition-delay: 1.3s;
				}

				svg path {
					stroke-dashoffset: 0;
					transition-delay: 1.7s;	
				}
			}

			.progress {
				&::after {
					transform: scaleY(1);
					transition-delay: 5.2s;
				}

				.item svg path {
					stroke-dashoffset: 182;
				}

				.item {
					/** @each: Delay. */
					@for $i from 1 to 8 {
						&:nth-child(#{$i}) {
							svg path {
								transition-delay: #{.5s * $i + 1.7s};
							}
						}
					}
				}
			}

			.wrapper {
				.item {
					.item-arrow svg path {
						stroke-dashoffset: 0;
						fill: #4AC4EB;
						stroke: transparent;
					}

					.item-icon,
					.item-description {
						opacity: 1;
					}

					/** @each: Delay. */
					@for $i from 1 to 8 {
						&:nth-child(#{$i}) {
							.item-arrow svg path {
								transition-delay: #{.5s * $i + 1.7s}, #{.5s * $i + 2.7s}, #{.5s * $i + 2.7s};
							}

							.item-icon {
								transition-delay: #{.5s * $i + 2.3s};
							}

							.item-description {
								transition-delay: #{.5s * $i + 2.5s};
							}
						}
					}
				}
			}

			.logo {
				&::before,
				&::after {
					opacity: 1;
					transform: scale(1) translate(-50%, -50%);
					transition-delay: 5.3s;
				}

				svg {
					opacity: 1;
					transform: scale(1);
					transition-delay: 5.4s;
				}
			}

			.scroll-down {
				transform: translateY(0);
				transition-delay: 5.3s;

				span {
					opacity: 1;
					transition-delay: 5.5s;
				}

				&--line {
					animation-name: scroll_down;
			    animation-duration: 3s;
			    animation-iteration-count: infinite;
			    animation-timing-function: ease(inout);
				}
			}
		}
	}

	/** Section content. */
	.section-content {
		background-color: #F9F9FA;
		position: relative;
		width: 100%;
		margin: 0;
		padding: 0 0 70px;

		.progress {
			position: absolute;
			top: -100px;
			right: 0;
			left: 0;

			&::after {
				content: '';
				background-color: #286DB3;
				position: absolute;
				bottom: 5px;
				left: 237px;
				width: 100vw;
				height: 2px;
				transform-origin: 0% 50%;
				transform: scaleX(0);
				transition: transform time(slow) linear;
			}

			svg path {
				stroke: #286DB3;
				stroke-width: 2px;
				stroke-dasharray: 1050;
				stroke-dashoffset: 1050;
				transition: stroke-dashoffset 1s linear;
			}
		}

		.flex-wrapper {
			position: relative;
			display: flex;
			justify-content: flex-end;
			padding-top: 50px;
			z-index: 2;

			.wrapper {
				position: relative;
				display: inline-flex;
				align-items: flex-start;
				padding: 45px 50px;

				&::before {
					content: '';
					background-color: white;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.07);
					transform-origin: 0% 50%;
					transform: scaleX(0);
					transition: transform time(default) ease(inout);
					z-index: 1;
				}
				
				.item {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					opacity: 0;
					transition: opacity time(default) ease(inout);
					z-index: 2;

					&:not(:last-child) {
						margin-right: 170px;

						/** Mobile. */
						@media (max-width: 1500px) {
							margin-right: 100px;
						}

						&::after {
							content: '';
							background-color: #1A1A1A;
							position: absolute;
							left: calc(100% + 60px);
							top: 122px;
							width: 50px;
							height: 2px;
							transform-origin: 0% 50%;
							transform: scaleX(0);
							transition: transform time(default) ease(inout);

							/** Mobile. */
							@media (max-width: 1500px) {
								left: calc(100% + 25px);
							}
						}
					}

					&-link {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 5;
					}
					
					&-icon {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 85px;
						height: 85px;

						&--background {
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							transform: rotate(45deg);
							border-radius: 20px;
							z-index: 1;
							transition: box-shadow time(default) ease(inout), transform time(default) ease(inout);
						}

						&--vector {
							position: relative;
							z-index: 2;

							svg path {
								fill: white;
							}
						}
					}

					&-content {
						text-align: center;
						margin-top: 30px;

						&--title {
							color: #2A2A2A;
							font-size: 18px;
							font-weight: 600;
							margin: 0 0 4px;
							padding: 0;
						}

						&--subtitle {
							color: #87868A;
							font-size: 14px;
							font-weight: normal;
							text-transform: uppercase;
							letter-spacing: .8px;
							margin: 0;
							padding: 0;
						}
					}

					&:hover .item-icon .item-icon--background {
						box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.18);
						transform: rotate(0);
					}

					/** Variations. */
					&.item-1 .item-icon .item-icon--background {
						background-image: linear-gradient(to top right, #25A6EC, #3EEDE3);
					}
					&.item-2 .item-icon .item-icon--background {
						background-image: linear-gradient(to top right, #8041EF, #FA8EE3);
					}
					&.item-3 .item-icon .item-icon--background {
						background-image: linear-gradient(to top right, #FC9E8F, #F0C640);
					}
					&.item-4 .item-icon .item-icon--background {
						background-image: linear-gradient(to top right, #10E37B, #00DFFD);
					}
					&.item-5 .item-icon .item-icon--background {
						background-image: linear-gradient(to top right, #2262A9, #3D92D1);
					}
					&.item-6 .item-icon .item-icon--background {
						background-image: linear-gradient(to top right, #BB1F93, #FE5C5F);
					}
				}
			}
		}

		.list {
			position: relative;
			display: block;
			margin: 87px 0 0;
			padding: 0 120px;
			z-index: 2;

			ul {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;

				li {
					position: relative;
					text-align: center;
					width: 290px;
					padding-top: 45px;

					&::before {
						content: '';
						background-color: #F9F9FA;
						background-image: url('../assets/images/general/icon-list.png');
						background-size: auto;
						background-repeat: no-repeat;
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						width: 21px;
						height: 21px;
						opacity: 0;
						transition: opacity time(default) ease(inout);
					}

					.title {
						color: color(blue, normal);
						font-size: 16px;
						font-weight: bold;
						text-transform: uppercase;
						margin: 0 0 10px;
						padding: 0;
						opacity: 0;
						transform: translateY(10px);
						transition: opacity time(default) ease(inout), transform time(slow) ease(inout);
					}

					.description {
						opacity: 0;
						transform: translateY(10px);
						transition: opacity time(default) ease(inout), transform time(slow) ease(inout);

						p {
							color: color(dark);
							font-size: 15px;
							font-weight: 500;
							line-height: 1.5;
						}
					}
				}
			}
		}

		/** Appear. */
		&.appear {
			.progress {
				svg path {
					stroke-dashoffset: 0;
				}

				&::after {
					transform: scaleX(1);
					transition-delay: 1s;
				}
			}

			.flex-wrapper .wrapper {
				&::before {
					transform: scaleX(1);
					transition-delay: .3s;
				}

				.item {
					opacity: 1;

					/** @each: Delay. */
					@for $i from 1 to 7 {
						&:nth-child(#{$i}) {
							transition-delay: #{.3s * $i + .3s};

							&::after {
								transition-delay: #{.3s * $i + .3s};
								transform: scaleX(1);
							}
						}
					}
				}
			}

			.list ul li {
				&::before {
					opacity: 1;
				}

				.title,
				.description {
					opacity: 1;
					transform: translateY(0px);
				}

				/** @each: Delay. */
				@for $i from 1 to 5 {
					&:nth-child(#{$i}) {
						&::before {
							transition-delay: #{.2s * $i + .8s};
						}

						.title {
							transition-delay: #{.2s * $i + 1s};
						}

						.description {
							transition-delay: #{.2s * $i + 1.3s};
						}
					}
				}
			}
		}
	}
}

#page-home-mobile {
	position: relative;
	width: 100%;
	padding: 60px 0 0;
	overflow: hidden;

	/** Section hero. */
	.section-hero {
		background-image: linear-gradient(to right, #49A7E3, #174E99);

		.wrapper {
			padding: 50px 0;

			.text {
				color: white;
				font-size: 21px;
				font-weight: 300;
				text-align: center;
			}

			.listing {
				position: relative;
				margin-top: 40px;
				
				.item {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					&:not(:last-child) {
						margin-bottom: 25px;
					}

					&-icon {
						background-color: white;
						position: relative;
						width: 80px;
						height: 80px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid #CFCFCF;
						border-radius: 50%;
						box-shadow: 10px 10px 30px rgba(0, 0, 0, .3);
						z-index: 2;

						svg {
							overflow: visible;
							transform-origin: 50% 50%;
							transform: scale(0.6);
						}

						span {
							background-image: linear-gradient(to right, #45A0DE, #2260A7);
							display: inline-block;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
						}
					}

					&-description {
						position: relative;
						width: 100%;
						text-align: center;
						margin-top: 10px;
						padding: 0 25px;

						p {
							color: white;
							font-size: 14px;
							font-weight: normal;
							font-style: italic;
							line-height: 1.4;
						}
					}

					&:nth-child(1) .item-icon span {
						font-size: 30px;
						height: 35px;
						font-weight: bold;
						user-select: none;
					}

					&:nth-child(5) .item-icon span {
						font-size: 20px;
						font-weight: bold;
						margin: 0 -7px;
						user-select: none;
					}

					&:nth-child(6) .item-icon svg {
						transform: scale(0.5);
					}
				}
			}
		}	
	}

	/** Section content. */
	.section-content {
		background-color: #F9F9FA;
		position: relative;
		width: 100%;
		margin: 0;
		padding: 70px 0 10px;

		.flex-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding-bottom: 50px;

			.item {
				position: relative;
				width: 150px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				&:not(:last-child) {
					margin-bottom: 40px;
				}

				&-link {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 5;
				}
				
				&-icon {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 85px;
					height: 85px;

					&--background {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						transform: rotate(45deg);
						border-radius: 20px;
						z-index: 1;
						transition: box-shadow time(default) ease(inout), transform time(default) ease(inout);
					}

					&--vector {
						position: relative;
						z-index: 2;

						svg path {
							fill: white;
						}
					}
				}

				&-content {
					text-align: center;
					margin-top: 30px;

					&--title {
						color: #2A2A2A;
						font-size: 18px;
						font-weight: 600;
						margin: 0 0 4px;
						padding: 0;
					}

					&--subtitle {
						color: #87868A;
						font-size: 14px;
						font-weight: normal;
						text-transform: uppercase;
						letter-spacing: .8px;
						margin: 0;
						padding: 0;
					}
				}

				/** Variations. */
				&.item-1 .item-icon .item-icon--background {
					background-image: linear-gradient(to top right, #25A6EC, #3EEDE3);
				}
				&.item-2 .item-icon .item-icon--background {
					background-image: linear-gradient(to top right, #8041EF, #FA8EE3);
				}
				&.item-3 .item-icon .item-icon--background {
					background-image: linear-gradient(to top right, #FC9E8F, #F0C640);
				}
				&.item-4 .item-icon .item-icon--background {
					background-image: linear-gradient(to top right, #10E37B, #00DFFD);
				}
				&.item-5 .item-icon .item-icon--background {
					background-image: linear-gradient(to top right, #2262A9, #3D92D1);
				}
				&.item-6 .item-icon .item-icon--background {
					background-image: linear-gradient(to top right, #BB1F93, #FE5C5F);
				}
			}
		}

		.list {
			background-color: white;
			padding: 50px 0;

			ul {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				flex-wrap: wrap;

				li {
					position: relative;
					width: 100%;
					text-align: center;
					padding: 37px 25px 0;

					&:not(:last-child) {
						margin-bottom: 30px;
					}

					&::before {
						content: '';
						background-color: #F9F9FA;
						background-image: url('../assets/images/general/icon-list.png');
						background-size: auto;
						background-repeat: no-repeat;
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						width: 21px;
						height: 21px;
					}

					.title {
						color: color(blue, normal);
						font-size: 16px;
						font-weight: bold;
						text-transform: uppercase;
						margin: 0 0 10px;
					}

					.description {
						p {
							color: color(dark);
							font-size: 15px;
							font-weight: 500;
							line-height: 1.5;
						}
					}
				}
			}
		}
	}
}
#page-portal-upload-plan {

	/** Section hero. */
	.section-page-hero .background {
		background-image: linear-gradient(to right, #B67E1D, #FFC000);
	}

	/** Section plan. */
	.section-plan {
		position: relative;
		margin: 60px 0 40px;
		padding-bottom: 40px;

		/** Mobile. */
		@media (max-width: 1023px) {
			margin: 50px 0 40px;
		}

		&::after {
			content: '';
			background-color: #C0C0C1;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 80%;
			height: 1px;
			z-index: 1;
		}

		.message p {
			color: black;
			font-size: 16px;
			font-weight: normal;
			line-height: 1.7;
			text-align: center;
		}

		.headline {
			display: flex;
			justify-content: center;
			margin-bottom: 55px;

			&-plan {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 50px;
				padding: 0 25px;
				border-radius: 30px;
				overflow: hidden;

				&::after {
					content: '';
					background-image: linear-gradient(to right, #B91E92, #FF5E5F);
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 1;
				}
				
				span {
					color: white;
					font-size: 16px;
					font-weight: bold;
					display: inline-block;
					position: relative;
					z-index: 2;
					transition: color time(default) ease(inout);
				}
			}
		}

		.flex-wrapper {
			display: flex;
			align-items: flex-start;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
				justify-content: center;
			}

			.image {
				position: relative;
				width: 230px;
				flex-shrink: 0;

				img {
					width: 100%;
				}
			}

			.content {
				margin-left: 55px;
				flex-grow: 1;

				/** Mobile. */
				@media (max-width: 1023px) {
					flex-grow: unset;
					width: 100%;
					margin: 40px 0 0;
				}

				> * {
					&:not(:last-child) {
						margin-bottom: 20px;
					}
				}

				p {
					color: #565656;
					font-size: 15px;
					font-weight: normal;
					line-height: 1.6;

					strong {
						color: color(blue, normal);
					}
				}

				ul li {
					position: relative;
					color: #565656;
					font-size: 15px;
					font-weight: normal;
					line-height: 1.6;
					padding-left: 30px;

					&:not(:last-child) {
						margin-bottom: 10px;
					}

					&::before {
						content: '';
						background-color: #F9F9FA;
						background-image: url('../assets/images/general/icon-list.png');
						background-size: auto;
						background-repeat: no-repeat;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
						width: 21px;
						height: 21px;
					}
				}
			}
		}
	}

	/** Section plans. */
	.section-plans {
		padding-bottom: 95px;

		/** Mobile. */
		@media (max-width: 1600px) {
			padding-bottom: 20px;
		}
		@media (max-width: 1023px) {
			padding-bottom: 40px;
		}
		
		.title {
			color: color(blue, normal);
			font-size: 16px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 30px;
		}

		.flex-wrapper {
			display: flex;
			justify-content: space-between;
			margin-top: 75px;

			/** Mobile. */
			@media (max-width: 1600px) {
				flex-wrap: wrap;
				justify-content: center;
			}

			.item {
				position: relative;
				width: 230px;

				/** Mobile. */
				@media (max-width: 1600px) {
					margin: 0 30px 50px;
				}
				@media (max-width: 1023px) {
					width: calc(50% - 15px);
					margin: 0;
				}

				&:not(:last-child) {

					/** Mobile. */
					@media (max-width: 1023px) {
						margin-right: 30px;
						margin-bottom: 50px;
					}
				}

				&:nth-child(2n + 0) {

					/** Mobile. */
					@media (max-width: 1023px) {
						margin-right: 0;
					}
				}

				&-link {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 5;
				}

				&-image {
					position: relative;
					width: 100%;
					height: 420px;
					border-radius: 10px;
					overflow: hidden;
					z-index: 2;

					/** Mobile. */
					@media (max-width: 1023px) {
						height: 200px;
					}

					&--background {
						background-color: #3C3C3C;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 1;
						transform-origin: 50% 50%;
						transition: transform time(default) ease(inout);

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							transition: opacity time(default) ease(inout);
						}
					}

					&--title {
						color: white;
						font-size: 18px;
						font-weight: bold;
						text-align: center;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						z-index: 2;
						opacity: 0;
						transition: opacity time(default) ease(inout);
					}
				}

				&-title {
					color: color(blue, normal);
					font-size: 16px;
					font-weight: 600;
					display: block;
					text-transform: uppercase;
					text-align: center;
					margin-top: 15px;
					transition: opacity time(default) ease(inout), transform time(default) ease(inout);
				}

				&-cta {
					position: absolute;
					right: 0;
					top: 410px;
					left: 0;
					height: 60px;
					z-index: 1;
					pointer-events: none;
					opacity: 0;
					transform: translateY(10px);
					transition: opacity time(default) ease(inout), transform time(default) ease(inout);

					button {
						background-image: linear-gradient(to right, #FD9E90, #EFC73F);
						color: white;
						font-size: 16px;
						font-weight: bold;
						text-transform: uppercase;
						text-align: center;
						width: 100%;
						height: 100%;
						padding-top: 10px;
						border-radius: 0 0 50px 50px;
					}
				}

				&:hover {
					.item-image {
						&--background {
							transform: scale(1.05);

							img {
								opacity: .3;
							}
						}

						&--title {
							opacity: 1;
							transition-delay: .15s;
						}
					}

					.item-title {
						transform: translateY(-5px);
						opacity: 0;
					}

					.item-cta {
						pointer-events: initial;
						opacity: 1;
						transform: translateY(0);
					}
				}
			}
		}
	}
}
#page-portal-archive {
	
	/** Section archive. */
	.section-archive {
		padding: 120px 0 70px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 50px 0;
		}

		.description {
			text-align: center;
			margin-bottom: 85px;

			/** Mobile. */
			@media (max-width: 1023px) {
				margin-bottom: 35px;
			}

			p {
				color: #565656;
				font-size: 15px;
				font-weight: normal;
				line-height: 1.7;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 14px;
				}
			}
		}

		.flex-wrapper {
			position: relative;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			.item {
				position: relative;
				display: flex;
				align-items: center;
				width: 320px;
				margin-bottom: 80px;

				/** Mobile. */
				@media (max-width: 1023px) {
					width: 100%;
					margin-bottom: 0;
				}

				&:not(:last-child) {
					margin-right: 50px;

					/** Mobile. */
					@media (max-width: 1500px) {
						margin-right: 0;
					}
					@media (max-width: 1023px) {
						margin-bottom: 40px;
					}
				}

				&:nth-child(3n + 0) {
					margin-right: 0;
				}

				&-icon {
					background-color: white;
					position: relative;
					width: 90px;
					height: 120px;
					box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
					border-radius: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-shrink: 0;
					padding-top: 4px;

					&--file {
						transform-origin: 50% 50%;
						transform: scale(1.3);

						svg path {
							fill: #989898;
						}
					}

					&--text {
						color: white;
						font-size: 14px;
						font-weight: bold;
						text-transform: uppercase;
						position: absolute;
						top: calc(50% + 10px);
						left: calc(50% - 4px);
						transform: translate(-50%, -50%);
					}
				}

				&-content {
					flex-grow: 1;
					padding-right: 10px;
					margin-left: 25px;

					&--title,
					&--subtitle {
						color: color(blue, normal);
						font-size: 16px;
						font-weight: bold;
					}

					&--subtitle {
						margin-top: 5px;
					}

					&--date {
						color: #565656;
						font-size: 15px;
						font-weight: normal;
						margin: 15px 0;
					}

					&--actions {
						display: flex;
						align-items: center;

						a {
							display: inline-block;
							
							&:not(:last-child) {
								margin-right: 20px;
							}

							svg path {
								fill: #989898;
								transition: fill time(default) ease(inout);
							}

							&:hover svg path {
								fill: color(blue, normal);
							}
						}
					}
				}
			}
		}

		.message p {
			color: black;
			font-size: 24px;
			font-weight: normal;
			display: block;
			text-align: center;
		}
	}
}
#page-portal-blog {
	
	/** Section hero. */
	.section-hero {
		padding: 70px 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 50px 0;
		}

		.flex-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
			}

			.title {
				color: color(blue, normal);
				font-size: 16px;
				font-weight: bold;
				text-transform: uppercase;
				margin-right: 35px;

				/** Mobile. */
				@media (max-width: 1023px) {
					margin: 0 0 25px;
				}
			}

			.categories {
				display: flex;
				align-items: center;

				/** Mobile. */
				@media (max-width: 1023px) {
					flex-wrap: wrap;
					justify-content: center;
				}

				.category {
					position: relative;
					display: flex;
					align-items: center;

					/** Mobile. */
					@media (max-width: 1500px) {
						flex-direction: column;
						justify-content: center;
					}
					@media (max-width: 1023px) {
						margin: 0 20px 20px;
					}

					&:not(:last-child) {
						margin-right: 25px;

						/** Mobile. */
						@media (max-width: 1023px) {
							margin-right: 20px;
						}
					}

					&-link {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 5;
					}

					&-icon {
						margin-right: 10px;

						/** Mobile. */
						@media (max-width: 1500px) {
							margin-right: 0;
							margin-bottom: 10px;
							height: 40px;
							display: flex;
							align-items: center;
						}

						svg path {
							fill: #747475;
							transition: fill time(default) ease(inout);
						}
					}

					&-title {
						color: color(blue, normal);
						font-size: 15px;
						font-weight: normal;
						white-space: nowrap;
					}

					&:hover .category-icon svg path {
						fill: color(blue, normal);
					}
				}
			}

			.search {
				margin-left: 105px;

				/** Mobile. */
				@media (max-width: 1500px) {
					margin-left: 50px;
				}
				@media (max-width: 1023px) {
					margin: 30px 0 0;
				}

				.form {
					display: flex;
					align-items: center;
					justify-content: center;

					.icon {
						flex-shrink: 0;
						margin-right: 10px;

						svg path {
							fill: #424242;
						}
					}

					.field-group {
						position: relative;
						width: 155px;
						
						.placeholder {
							color: #404040;
							font-size: 12px;
							font-weight: bold;
							text-transform: uppercase;
							letter-spacing: .8px;
							position: absolute;
							top: 5px;
							left: 0;
							pointer-events: none;
							transition: opacity time(default) ease(inout), transform time(default) ease(inout);
						}

						.field {
							background-color: transparent;
							color: #404040;
							font-size: 15px;
							font-weight: 500;
							width: 100%;
							padding-bottom: 5px;
							border: none;
							border-bottom: 1px solid #404040;
						}

						&.focus,
						&.not-empty {
							.placeholder {
								opacity: 0;
								transform: translateX(10px);
							}
						}
					}

					.form-submit {
						margin-left: 10px;

						svg path {
							fill: color(blue, normal);
							transition: fill time(default) ease(inout);
						}

						&:hover svg path {
							fill: #404040;
						}
					}
				}
			}
		}

		.headline {
			margin-top: 65px;
			display: flex;
			align-items: center;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
				justify-content: center;
				margin-top: 50px;
			}

			&-count {
				color: #565656;
				font-size: 15px;
				font-weight: normal;
			}

			&-category {
				flex-grow: 1;
				display: flex;
				align-items: center;
				justify-content: center;

				/** Mobile. */
				@media (max-width: 1023px) {
					margin-top: 15px;
				}

				&--icon {
					transform-origin: 50% 50%;
					transform: scale(1.3);

					svg path {
						fill: color(blue, normal);
					}
				}

				&--title {
					color: color(blue, normal);
					z-index: 15;
					font-weight: bold;
					margin-left: 10px;
				}
			}
		}
	}

	/** Section listing. */
	.section-listing {
		.flex-wrapper {
			display: flex;
			flex-wrap: wrap;

			.item {
				position: relative;
				width: 25%;
				height: 365px;
				/** Mobile. */
				@media (max-width: 1500px) {
					height: 280px;
				}
				@media (max-width: 1023px) {
					width: 100%;
					height: 250px;
				}
				
				&-link {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 5;
				}

				&-image {
					background-color: black;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 1;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						transition: opacity time(default) ease(inout);

						/** Mobile. */
						@media (max-width: 1023px) {
							opacity: .6;
						}
					}
				}

				&-dots {
					display: flex;
					align-items: center;
					position: absolute;
					top: calc(50% - 20px);
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 2;

					span {
						background-color: white;
						width: 5px;
						height: 5px;
						border-radius: 50%;
						transform: translateX(10px);
						opacity: 0;
						transition: opacity time(fast) ease(inout), transform time(default) ease(inout);

						/** Mobile. */
						@media (max-width: 1023px) {
							opacity: 1;
							transform: translateX(0);
						}

						&:not(:last-child) {
							margin-right: 5px;
						}
					}
				}

				&-title {
					color: white;
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
					text-align: center;
					line-height: 1.3;
					width: 160px;
					position: absolute;
					top: calc(50% + 20px);
					left: 50%;
					transform: translate(-50%, calc(-50% + 10px));
					opacity: 0;
					transition: opacity time(default) ease(inout), transform time(slow) ease(inout);
					z-index: 2;

					/** Mobile. */
					@media (max-width: 1023px) {
						opacity: 1;
						transform: translate(-50%, -50%);
					}
				}

				/** Desktop. */
				@media (min-width: 1024px) {
					&:hover {
						.item-image img {
							opacity: .3;
						}

						.item-dots span {
							opacity: 1;
							transform: translateX(0);

							&:nth-child(1) {
								transition-delay: .15s;
							}
							&:nth-child(2) {
								transition-delay: .2s;
							}
							&:nth-child(3) {
								transition-delay: .25s;
							}
						}

						.item-title {
							opacity: 1;
							transform: translate(-50%, -50%);
							transition-delay: .25s;
						}
					}
				}
			}
		}
	}

	/** Section archive. */
	.section-archive {
		padding: 65px 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 50px 0;
		}

		.flex-wrapper {
			display: flex;
			align-items: flex-start;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
			}

			.slide {
				position: relative;
				width: 390px;
				flex-shrink: 0;

				/** Mobile. */
				@media (max-width: 1023px) {
					width: 100%;
				}

				&-category {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 15px;

					&--icon svg path {
						fill: #3C3C3C;
					}

					&--title {
						color: #3C3C3C;
						font-size: 15px;
						font-weight: bold;
						margin-left: 10px;
					}
				}

				&-items {
					background-color: black;
					position: relative;
					width: 100%;
					z-index: 1;
					box-shadow: -8.222px 3.661px 46px 0px rgba(0, 0, 0, 0.13);

					.item {
						position: relative;
						width: 100%;
						height: 380px;

						/** Mobile. */
						@media (max-width: 1023px) {
							height: 280px;
						}

						&-link {
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							z-index: 5;
						}

						&-icon {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							z-index: 2;
						}

						&-image {
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							z-index: 1;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						/** Video. */
						&.item-video .item-image {
							img {
								opacity: .6;
							}
						}
					}
				}

				&-dots {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 20px;

					.owl-dot {
						color: #3C3C3C;
						font-size: 12px;
						font-weight: bold;
						transition: color time(default) ease(inout);
						cursor: pointer;
						padding: 5px;

						&:not(:last-child) {
							margin-right: 5px;
						}

						&:hover,
						&.active {
							color: color(blue, normal);
						}
					}
				}
			}

			.content {
				flex-grow: 1;
				margin-left: 60px;

				/** Mobile. */
				@media (max-width: 1023px) {
					margin: 40px 0 0;
					flex-grow: unset;
					width: 100%;
				}

				&-title {
					color: color(blue, normal);
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
					margin-bottom: 15px;
				}

				&-description {
					padding-bottom: 45px;

					> * {
						&:not(:last-child) {
							margin-bottom: 20px;
						}
					}

					p {
						color: #565656;
						font-size: 15px;
						font-weight: normal;
						line-height: 1.6;

						strong {
							color: color(blue, normal);
						}
					}

					ul li {
						position: relative;
						color: #565656;
						font-size: 15px;
						font-weight: normal;
						line-height: 1.6;
						padding-left: 30px;

						&:not(:last-child) {
							margin-bottom: 10px;
						}

						&::before {
							content: '';
							background-color: #F9F9FA;
							background-image: url('../assets/images/general/icon-list.png');
							background-size: auto;
							background-repeat: no-repeat;
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							width: 21px;
							height: 21px;
						}
					}
				}

				&-return {
					padding-top: 15px;
					border-top: 1px solid #C0C0C1;
					display: flex;
					justify-content: center;

					a {
						display: flex;
						align-items: center;

						svg {
							transform: translateX(0);
							transition: transform time(default) ease(inout);

							path {
								fill: #434343;
								transition: fill time(default) ease(inout);
							}
						}

						span {
							color: #3C3C3C;
							font-size: 12px;
							font-weight: bold;
							margin-left: 10px;
							transition: color time(default) ease(inout);
						}

						&:hover {
							svg {
								transform: translateX(-10px);

								path {
									fill: color(blue, normal);
								}
							}

							span {
								color: color(blue, normal);
							}
						}
					}
				}
			}
		}
	}
}
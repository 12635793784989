.modal-plans {
	display: flex;
	align-items: center;
	justify-content: center;

	/** Mobile. */
	@media (max-width: 1023px) {
		background-color: white;
		display: block;
		overflow: auto;
	}

	.wrapper {
		background-color: white;
		display: flex;
		align-items: flex-start;
		position: relative;
		width: 1090px;
		border-radius: 30px;
		padding-bottom: 30px;

		/** Mobile. */
		@media (max-width: 1023px) {
			width: 100%;
			border-radius: 0px;
			display: block;
			padding: 60px 0 30px;
		}

		&-close {
			display: flex;
			align-items: center;
			position: absolute;
			top: -25px;
			right: 30px;
			z-index: 3;

			/** Mobile. */
			@media (max-width: 1023px) {
				top: 20px;
				right: 20px;
			}

			span {
				color: white;
				font-size: 12px;
				font-weight: bold;
				text-transform: uppercase;
				margin-right: 10px;
				transition: color time(default) ease(inout);

				/** Mobile. */
				@media (max-width: 1023px) {
					color: color(blue, normal);
				}
			}

			svg path {
				fill: white;
				transition: fill time(default) ease(inout);

				/** Mobile. */
				@media (max-width: 1023px) {
					fill: color(blue, normal);
				}
			}

			/** Desktop. */
			@media (min-width: 1024px) {
				&:hover {
					span {
						color: color(blue, light);
					}

					svg path {
						fill: color(blue, light);
					}
				}
			}
		}

		&-image {
			position: relative;
			width: 390px;
			height: 380px;
			flex-shrink: 0;
			top: -40px;
			left: -40px;
			border-radius: 20px;
			overflow: hidden;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 280px;
				height: 350px;
				top: 0;
				left: 0;
				margin: 0 auto;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-content {
			flex-grow: 1;
			padding: 45px 30px 0 0;
			max-height: 70vh;
			overflow: auto;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-grow: unset;
				width: 100%;
				padding: 40px 20px 0;
			}

			&--title {
				color: color(blue, normal);
				font-size: 16px;
				font-weight: bold;
				text-transform: uppercase;
				margin-bottom: 10px;
			}

			&--description {
				margin-bottom: 50px;

				/** Mobile. */
				@media (max-width: 1023px) {
					margin-bottom: 30px;
				}

				> * {
					&:not(:last-child) {
						margin-bottom: 20px;
					}
				}

				p {
					color: color(grey);
					font-size: 15px;
					font-weight: normal;
					line-height: 1.7;
				}

				ul {
					li {
						position: relative;
						color: color(grey);
						font-size: 15px;
						font-weight: normal;
						line-height: 1.7;
						padding: 0 0 0 30px;
						display: block;

						&:not(:last-child) {
							margin-bottom: 10px;
						}

						&::before {
							content: '';
							background-image: url('../assets/images/general/icon-list.png');
							background-size: auto;
							background-repeat: no-repeat;
							position: absolute;
							top: 2px;
							left: 0;
							width: 21px;
							height: 21px;
						}
					}
				}
			}

			&--cta {
				margin-bottom: 10px;

				/** Mobile. */
				@media (max-width: 1023px) {
					margin-bottom: 25px;
				}

				button {
					background-image: linear-gradient(to right, #174E98, #49A7E3);
					display: flex;
					align-items: center;
					justify-content: center;
					width: 220px;
					height: 50px;
					border-radius: 50px;

					span {
						color: white;
						font-size: 16px;
						font-weight: bold;
						text-transform: uppercase;
						margin-right: 13px;
					}

					svg path {
						fill: white;
					}
				}
			}

			&--message {
				p {
					color: color(grey);
					font-size: 14px;
					font-weight: normal;
					line-height: 1.4;
				}
			}
		}
	}
}
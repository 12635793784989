#page-testimonials {
	position: relative;
	width: 100%;
	padding: 60px 60px 0;
	overflow: hidden;

	/** Mobile. */
	@media (max-width: 1200px) {
		padding: 60px 0 0;
	}

	/** Section page hero. */
	.section-page-hero .background {
		background-image: linear-gradient(to right, #11E379, #00DEFF);
	}

	/** Section testimonials. */
	.section-testimonials {
		position: relative;
		padding-top: 80px;
		min-height: 520px;
		margin-bottom: 80px;

		/** Mobile. */
		@media (max-width: 1023px) {
			overflow: hidden;
			padding-bottom: 500px;
		}

		.wrapper {
			position: relative;
			width: 540px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}

			.tabs {
				position: relative;
				width: 100%;
				transition: height time(default) ease(inout);
				transition-delay: .2s;
				z-index: 1;
				
				.tab-content {
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					padding-top: 150px;
					z-index: 1;
					opacity: 0;
					visibility: hidden;
					transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

					&.active {
						opacity: 1;
						visibility: visible;
						transition-delay: .4s
					}

					.description {
						p {
							color: color(grey);
							font-size: 16px;
							font-weight: normal;
							line-height: 1.7;
						}
					}
				}
			}

			.navigation {
				position: absolute;
				display: flex;
				align-items: center;
				height: 110px;
				top: 0;
				right: 0;
				left: 0;
				z-index: 2;

				&::before {
					content: '';
					background-image: linear-gradient(to right, #49A7E3, #19519B);
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: calc(-20vw);
					z-index: 1;

					/** Mobile. */
					@media (max-width: 1023px) {
						right: -25px;
					}
				}

				span {
					color: white;
					font-size: 16px;
					font-weight: bold;
					display: inline-block;
					position: relative;
					z-index: 2;

					/** Mobile. */
					@media (max-width: 1023px) {
						text-align: center;
						line-height: 1.4;
					}
				}

				.arrows {
					position: relative;
					display: flex;
					align-items: center;
					bottom: -2px;
					z-index: 2;
					margin-left: 40px;

					.arrow {
						position: relative;
						display: inline-block;
						

						&:not(:last-child) {
							margin-right: 30px;
						}

						svg path {
							fill: white;
							transition: fill time(default) ease(inout);
						}

						&:hover svg path {
							fill: color(blue, light);
						}
					}
				}
			}
		}

		.slide {
			position: absolute;
			top: 80px;
			left: calc(50% - 10px);
			z-index: 2;
			margin: 0;
			padding: 0;

			/** Mobile. */
			@media (max-width: 1200px) {
				left: calc(50% + 50px);
			}
			@media (max-width: 1023px) {
				top: unset;
				left: 20px;
				bottom: 0;
			}

			&-items {
				position: relative;
				display: flex;
				align-items: center;
				width: 100%;
				height: 425px;
				margin: 0;
				padding: 0;

				.item {
					will-change: width, transform, margin;
					position: relative;
					width: 270px;
					height: 100%;
					margin: 0;
					padding: 0;
					border-radius: 10px;
					overflow: hidden;
					transform-origin: 50% 50%;
					transition: width time(default) ease(inout), transform time(default) ease(inout), margin time(default) ease(inout);

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 100px;
					}

					&:not(:last-child) {
						margin-right: 20px;
					}

					&-link {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 5;
					}

					&-image {
						background-color: color(blue, normal);
						position: absolute;
						top: 0;
						bottom: 0;
						left: 50%;
						width: 425px;
						transform: translateX(-50%);
						z-index: 1;
						pointer-events: none;
						transform-origin: 0% 50%;
						transform: translateX(-50%);
						transition: transform time(default) ease(inout);

						img {
							position: relative;
							width: 100%;
							height: 100%;
							object-fit: cover;
							user-select: none;
							opacity: .3;
							transition: opacity time(default) ease(inout);
							z-index: 1;
						}
					}

					&-icon {
						position: absolute;
						top: calc(50% - 35px);
						left: 50%;
						transform: translate(-50%, -50%);
						z-index: 3;
						opacity: 0;
						transition: opacity time(default) ease(inout);
					}

					&-title {
						will-change: opacity, transform;
						color: white;
						font-size: 15px;
						font-weight: normal;
						position: absolute;
						top: calc(50% + 35px);
						left: 50%;
						opacity: 0;
						transform: translate(-50%, 50%);
						white-space: nowrap;
						z-index: 4;
						opacity: 1;
						transition: opacity time(default) ease(inout), transform time(default) ease(inout);
					}

					&:hover .item-image {
						transform: scale(1.05) translateX(-50%);
					}

					&.inactive {
						width: 100px;
						margin: 0 -30px 0 0;
						transform: scale(0.9);

						&:nth-child(2),
						&:nth-child(3) {
							margin: 0 -30px 0 -70px;
						}

						.item-category {
							opacity: 0;
						}
					}

					&.active {
						width: 425px;
						transform: scale(1);

						/** Mobile. */
						@media (max-width: 1023px) {
							width: 230px;
						}

						.item-image img {
							opacity: 1;
						}

						.item-icon {
							opacity: 1;
						}

						.item-title {
							opacity: 0;
							transform: translate(-50%, calc(50% + 10px));
						}
					}
				}
			}
		}
	}
}
.modal-remove-consultation {
	display: flex;
	align-items: center;
	justify-content: center;

	.wrapper {
		background-color: white;
		position: relative;
		width: 915px;
		border-radius: 30px;
		padding: 35px 120px 40px;

		/** Mobile. */
		@media (max-width: 1023px) {
			width: 95%;
			padding: 30px 20px;
		}

		&-close {
			display: flex;
			align-items: center;
			position: absolute;
			top: -25px;
			right: 30px;
			z-index: 3;

			span {
				color: white;
				font-size: 12px;
				font-weight: bold;
				text-transform: uppercase;
				margin-right: 10px;
				transition: color time(default) ease(inout);
			}

			svg path {
				fill: white;
				transition: fill time(default) ease(inout);
			}

			&:hover {
				span {
					color: color(blue, light);
				}

				svg path {
					fill: color(blue, light);
				}
			}
		}

		&-title {
			color: color(blue, normal);
			font-size: 16px;
			font-weight: bold;
			text-transform: uppercase;
			text-align: center;
			display: block;
			margin-bottom: 35px;

			/** Mobile. */
			@media (max-width: 1023px) {
				font-size: 15px;
				margin-bottom: 15px;
				padding: 0 25px;
				line-height: 1.4;
			}
		}

		&-description {

			/** Mobile. */
			@media (max-width: 1023px) {
				text-align: center;
			}

			p {
				color: #565656;
				font-size: 16px;
				font-weight: normal;
				line-height: 1.4;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 14px;
				}
			}
		}

		&-actions {
			margin-top: 25px;
			display: flex;
			align-items: center;
			justify-content: center;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
			}

			button {
				background-size: 200% 100%;
				background-position: left center;
				display: flex;
				align-items: center;
				justify-content: center;
				width: auto;
				height: 50px;
				padding: 0 40px;
				border-radius: 50px;
				transition: background-position time(default) ease(inout);

				&:hover {
					background-position: right center;
				}

				/** Mobile. */
				@media (max-width: 1023px) {
					&:nth-child(1) {
						margin-bottom: 20px;
					}
				}

				span {
					color: white;
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
					margin-right: 13px;
				}

				&:not(:last-child) {
					margin-right: 20px;
				}

				&:nth-child(1) {
					background-image: linear-gradient(to right, #174E98, #49A7E3, #174E98);
				}

				&:nth-child(2) {
					background-image: linear-gradient(to right, #C0C0C0, #DDDDDD, #C0C0C0);

					span {
						color: black;
					}
				}
			}
		}
	}
}
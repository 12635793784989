/** @import: Vendor. */
@import "../vendor/normalize/normalize.css";
@import "../vendor/owl/owl.carousel.min.css";
@import "../vendor/classyedit/jquery.classyedit.css";
// @import "../vendor/fancybox/jquery.fancybox.min.css";

/** @import: Main. */
@import "reset";
@import "vars";
@import "fonts";

/** Body. */
body {
  background-color: #F9F9FA;
  font-family: 'Poppins';
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 60px;

  /** Mobile. */
  @media (max-width: 1200px) {
    padding-bottom: 0;
  }

  &::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms;
  }

  &::after {
    content: '';
    background-color: white;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    z-index: 50;

    /** Mobile. */
    @media (max-width: 1200px) {
      display: none;
    }
  }

  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      opacity: .6;
      pointer-events: initial;
    }
  }
}

/** Layout. */
.container {
  width: calc(100% - 50px);
  max-width: 1360px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1450px) {
    max-width: 1200px;
  }
  @media (max-width: 1200px) {
    width: 100%;
  	padding: 0 25px;
  }
}

/** @import: Globals. */
@import "globals/header";
@import "globals/footer";
@import "globals/menu";

/** @import: Elements. */
@import "elements/general";
@import "elements/progress-screen";

/** @import: Modals. */
@import "modals/general";
@import "modals/plans";
@import "modals/plans-payment";
@import "modals/remove-consultation";
@import "modals/schedule";

/** @import: Sections. */
@import "sections/page-hero";

/** @import: Pages [Website]. */
@import "pages/home";
@import "pages/about";
@import "pages/how-it-works";
@import "pages/programs";
@import "pages/testimonials";
@import "pages/contact";
@import "pages/user-access";
@import "pages/terms";
@import "pages/search";
@import "pages/schedule";
@import "pages/filter";

/** @import: Pages [Portal]. */
@import "pages/portal/general";
@import "pages/portal/home";
@import "pages/portal/data";
@import "pages/portal/calendar";
@import "pages/portal/chat";
@import "pages/portal/forum";
@import "pages/portal/blog";
@import "pages/portal/daily";
@import "pages/portal/archive";
@import "pages/portal/upload-plan";
/** Transition slider owl carousel. */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-in {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  transition-delay: .6s;
}
.fade-out {
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}

/** Animate lazy load. */
.image-lazy-load {
	@keyframes lazy_load {
		0% { transform: scaleX(0); transform-origin: 0% 50%; }
		50% { transform: scaleX(1); transform-origin: 0% 50%; }
		50.1% { transform: scaleX(1); transform-origin: 100% 50%; }
		100% { transform: scaleX(0); transform-origin: 100% 50%; }
	}

	&:not(.no-animate) {
		&::after {
			content: '';
			background-color: #CCC;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			transition: opacity time(default) ease(inout);
			opacity: 0;
		}
	}

	img {
		position: relative;
		z-index: 2;
		opacity: 0;
		transition: opacity time(default) ease(inout);
	}

	&:not(.no-animate) img {
		transition: opacity time(slow) ease(inout);
	}

	&.loading {
		&:not(.no-animate) {
			&::after {
				opacity: 1;
				animation-name: lazy_load;
				animation-duration: 2s;
				animation-iteration-count: infinite;
				animation-timing-function: ease(inout);
			}
		}
	}

	&.loaded {
		&:not(.no-animate) {
			&::after {
				opacity: 0;
			}
		}

		img {
			opacity: 1;
		}
	}
}

/** Scroll down. */
@keyframes scroll_down {
	0% { transform: scaleY(0); transform-origin: 50% 0%; }
	25.5% { transform: scaleY(1); transform-origin: 50% 0%; }
	25.6% { transform: scaleY(1); transform-origin: 50% 100%; }
	50%, 100% { transform: scaleY(0); transform-origin: 50% 100%; }
}

/** Languages. */
.goog-te-menu-value:hover { text-decoration: none !important; }
.goog-text-highlight { background-color: transparent !important; box-shadow: none !important; }
body { top: 0 !important; }
#goog-gt-tt,
#google_translate_element2,
.goog-te-banner-frame { display: none !important; }
#page-contact {
	position: relative;
	width: 100%;
	padding: 60px 60px 0;
	overflow: hidden;

	/** Mobile. */
	@media (max-width: 1200px) {
		padding: 60px 0 0;
	}

	/** Section page hero. */
	.section-page-hero .background {
		background-image: linear-gradient(to right, #184E99, #49A7E3);
	}

	/** Section contact. */
	.section-contact {
		position: relative;
		padding: 95px 0 40px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 50px 0 40px;
		}

		.image {
			position: absolute;
			top: 90px;
			right: calc(50% + 40px);
			width: 875px;
			height: 445px;

			/** Mobile. */
			@media (max-width: 1500px) {
				width: 650px;
				height: 350px;
			}
			@media (max-width: 1200px) {
				right: calc(50% + 130px);
			}
			@media (max-width: 1023px) {
				position: relative;
				top: unset;
				right: unset;
				width: 100%;
				height: 300px;
			}

			&::before {
				content: '';
				background-image: linear-gradient(to top right, #2C73B7, #3582C4);
				position: absolute;
				top: 15px;
				right: -15px;
				bottom: -15px;
				left: 15px;
				z-index: 1;

				/** Mobile. */
				@media (max-width: 1023px) {
					display: none;
				}
			}

			img {
				position: relative;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 2;
				user-select: none;
			}
		}
		
		.wrapper {
			position: relative;
			width: 565px;
			margin: 0 0 0 auto;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
				margin: 40px 0 0;
			}

			.wrapper-form {
				.form-row {
					display: flex;
					align-items: center;
					justify-content: space-between;

					/** Mobile. */
					@media (max-width: 1023px) {
						flex-wrap: wrap;
					}

					&:not(:last-child) {
						margin-bottom: 30px;
					}

					.field-group {
						width: calc(50% - 15px);
						position: relative;
						margin-right: 10px;

						/** Mobile. */
						@media (max-width: 1023px) {
							width: 100%;
							margin-right: 0;
						}

						/** Mobile. */
						&:not(:last-child) {
							@media (max-width: 1023px) {
								margin-bottom: 30px;
							}
						}
						
						.placeholder {
							color: color(dark);
							font-size: 15px;
							font-weight: 500;
							position: absolute;
							top: 3px;
							left: 0;
							pointer-events: none;
							transform-origin: 0% 0%;
							transition: transform time(default) ease(inout);
						}

						.field {
							background-color: transparent;
							color: color(dark);
							font-size: 15px;
							font-weight: 500;
							width: 100%;
							padding-bottom: 5px;
							border: none;
							border-bottom: 1px solid #3C3C3C;
							transition: border-color time(default) ease(inout);
						}

						textarea {
							height: 60px;
							resize: none;
						}

						&.focus,
						&.not-empty {
							.placeholder {
								transform: translateY(-20px) scale(.8);
							}

							.field {
								border-color: color(blue, normal);
							}
						}

						&.invalid .field {
							border-color: red;
						}
					}

					&.form-row--full {
						flex-wrap: wrap;

						.field-group {
							width: 100%;
						}
					}
				}

				.form-submit {
					
					/** Mobile. */
					@media (max-width: 1023px) {
						display: flex;
						justify-content: center;
					}

					button {
						background-image: linear-gradient(to right, #174E98, #49A7E3, #174E98);
						background-size: 200% 100%;
						background-position: left center;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 220px;
						height: 50px;
						border-radius: 50px;
						transition: background-position time(default) ease(inout);

						&:hover {
							background-position: right center;
						}

						span {
							color: white;
							font-size: 16px;
							font-weight: bold;
							text-transform: uppercase;
							margin-right: 13px;
						}

						svg path {
							fill: white;
						}
					}
				}
			}
			
			.info {
				margin: 55px 0 65px;

				&-item {
					display: flex;
					align-items: center;

					&:not(:last-child) {
						margin-bottom: 7px;
					}

					&--label,
					&--value {
						color: color(dark);
						font-size: 13px;
					}

					&--label {
						font-weight: bold;
						margin-right: 5px;
					}

					&--value {
						font-weight: 500;
					}
				}
			}

			.newsletter {
				&-title {
					color: color(blue, normal);
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
				}

				&-form {
					margin-top: 10px;

					.form {
						display: flex;
						align-items: center;

						.field-group {
							position: relative;
							margin-right: 10px;
							
							.placeholder {
								color: color(dark);
								font-size: 15px;
								font-weight: 500;
								position: absolute;
								top: 3px;
								left: 0;
								pointer-events: none;
								transition: opacity time(default) ease(inout), transform time(default) ease(inout);
							}

							.field {
								background-color: transparent;
								color: color(dark);
								font-size: 15px;
								font-weight: 500;
								width: 275px;
								padding-bottom: 5px;
								border: none;
								border-bottom: 1px solid color(blue, normal);
								transition: border-color time(default) ease(inout);
							}

							&.focus,
							&.not-empty {
								.placeholder {
									opacity: 0;
									transform: translateX(10px);
								}
							}

							&.invalid .field {
								border-color: red;
							}
						}

						.form-submit {
							button {
								display: inline-block;

								svg {
									transition: transform time(default) ease(inout);
								}

								&:hover svg {
									transform: translateX(10px);
								}
							}
						}
					}
				}
			}
		}

		.flex-wrapper {
			display: flex;
			align-items: center;
			margin-top: 125px;

			.copyright {
				span {
					color: color(dark);
					font-size: 12px;
					font-weight: normal;
					line-height: 1.2;
				}
			}

			.vive {
				margin-left: 120px;

				a {
					display: inline-block;
					
					img {
						transition: filter time(default) ease(inout);
					}

					&:hover img {
						filter: grayscale(100%);
					}
				}
			}
		}
	}
}
#page-programs {
	position: relative;
	width: 100%;
	padding: 60px 60px 0;
	overflow: hidden;

	/** Mobile. */
	@media (max-width: 1200px) {
		padding: 60px 0 0;
	}

	/** Section page hero. */
	.section-page-hero .background {
		background-image: linear-gradient(to right, #FD9E90, #EFC73F);
	}

	/** Section content. */
	.section-content {
		padding: 90px 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 50px 0 75px;
		}

		.description {
			p {
				color: color(grey);
				font-size: 15px;
				font-weight: 500;
				line-height: 1.7;

				&:not(:last-child) {
					margin-bottom: 20px;
				}

				strong {
					color: color(blue, normal);
				}
			}
		}

		.flex-wrapper {
			display: flex;
			justify-content: space-between;
			margin-top: 75px;

			/** Mobile. */
			@media (max-width: 1600px) {
				flex-wrap: wrap;
				justify-content: center;
			}

			.item {
				position: relative;
				width: 230px;

				/** Mobile. */
				@media (max-width: 1660px) {
					margin: 0 30px 60px;
				}
				@media (max-width: 1023px) {
					width: calc(50% - 15px);
					margin: 0;
				}

				&:not(:last-child) {

					/** Mobile. */
					@media (max-width: 1023px) {
						margin-right: 30px;
						margin-bottom: 50px;
					}
				}

				&:nth-child(2n + 0) {

					/** Mobile. */
					@media (max-width: 1023px) {
						margin-right: 0;
					}
				}

				&-link {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 5;
				}

				&-image {
					position: relative;
					width: 100%;
					height: 420px;
					border-radius: 10px;
					overflow: hidden;
					z-index: 2;

					/** Mobile. */
					@media (max-width: 1023px) {
						height: 200px;
					}

					&--background {
						background-color: #3C3C3C;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 1;
						transform-origin: 50% 50%;
						transition: transform time(default) ease(inout);

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							transition: opacity time(default) ease(inout);
						}
					}

					&--title {
						color: white;
						font-size: 18px;
						font-weight: bold;
						text-align: center;
						white-space: nowrap;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						z-index: 2;
						opacity: 0;
						transition: opacity time(default) ease(inout);
					}
				}

				&-title {
					color: color(blue, normal);
					font-size: 16px;
					font-weight: 600;
					display: block;
					text-transform: uppercase;
					white-space: nowrap;
					text-align: center;
					margin-top: 15px;
					transition: opacity time(default) ease(inout), transform time(default) ease(inout);
				}

				&-cta {
					position: absolute;
					right: 0;
					top: 410px;
					left: 0;
					height: 60px;
					z-index: 1;
					pointer-events: none;
					opacity: 0;
					transform: translateY(10px);
					transition: opacity time(default) ease(inout), transform time(default) ease(inout);

					button {
						background-image: linear-gradient(to right, #FD9E90, #EFC73F);
						color: white;
						font-size: 16px;
						font-weight: bold;
						text-transform: uppercase;
						text-align: center;
						width: 100%;
						height: 100%;
						padding-top: 10px;
						border-radius: 0 0 50px 50px;
					}
				}

				&:hover {
					.item-image {
						&--background {
							transform: scale(1.05);

							img {
								opacity: .3;
							}
						}

						&--title {
							opacity: 1;
							transition-delay: .15s;
						}
					}

					.item-title {
						transform: translateY(-5px);
						opacity: 0;
					}

					.item-cta {
						pointer-events: initial;
						opacity: 1;
						transform: translateY(0);
					}
				}
			}
		}
	}
}
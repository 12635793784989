#main-menu {
	background-color: white;
	position: fixed;
	top: 60px;
	right: 0;
	bottom: 60px;
	left: 0;
	z-index: 200;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

	&::before {
		content: '';
		background-color: white;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 60px;
		z-index: 1;
	}

	&::after {
		content: '';
		background-color: white;
		position: absolute;
		top: -60px;
		left: 200px;
		right: 500px;
		height: 60px;
		z-index: 1;
	}

	.background {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 350px;
		padding-left: 55px;

		/** Variations. */
		&.bg-home {
			background-image: linear-gradient(to right bottom, #49A8E3, #174E98);
		}

		&.bg-about {
			background-image: linear-gradient(to right bottom, #24A6EC, #3FEFE3);
		}

		&.bg-contact {
			background-image: linear-gradient(to right bottom, #184E99, #49A7E3);
		}

		&.bg-how-it-works {
			background-image: linear-gradient(to right bottom, #7D3FEF, #FD90E2);
		}

		&.bg-programs {
			background-image: linear-gradient(to right bottom, #FD9E90, #EFC73F);
		}

		&.bg-terms {
			background-image: linear-gradient(to right bottom, #B91E92, #FF5E5F);
		}

		&.bg-testimonials {
			background-image: linear-gradient(to right bottom, #11E379, #00DEFF);
		}
	}

	.logo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;

		svg {
			opacity: .05;
		}
	}

	.flex-wrapper {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: space-between;
		top: 0;
		right: 0;
		bottom: 0;
		left: 350px;
		padding: 0 300px 0 105px;
		z-index: 2;

		.info {
			position: relative;

			&-contact {
				&-title {
					color: color(blue, normal);
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
					opacity: 0;
					transition: opacity time(default) ease(inout);
				}

				.info-listing {
					margin-top: 20px;

					.info-item {
						display: flex;
						align-items: center;
						opacity: 0;
						transform: translateX(10px);
						transition: opacity time(default) ease(inout), transform time(slow) ease(inout);

						&:not(:last-child) {
							margin-bottom: 7px;
						}

						&--label,
						&--value {
							color: color(dark);
							font-size: 13px;
						}

						&--label {
							font-weight: bold;
							margin-right: 5px;
						}

						&--value {
							font-weight: 500;
						}
					}
				}
			}
			
			.newsletter {
				margin-top: 90px;
				opacity: 0;
				transition: opacity time(default) ease(inout);

				&-title {
					color: color(blue, normal);
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
				}

				&-form {
					margin-top: 10px;

					.form {
						display: flex;
						align-items: center;

						.field-group {
							position: relative;
							margin-right: 10px;
							
							.placeholder {
								color: color(dark);
								font-size: 15px;
								font-weight: 500;
								position: absolute;
								top: 3px;
								left: 0;
								pointer-events: none;
								transition: opacity time(default) ease(inout), transform time(default) ease(inout);
							}

							.field {
								background-color: transparent;
								color: color(dark);
								font-size: 15px;
								font-weight: 500;
								width: 275px;
								padding-bottom: 5px;
								border: none;
								border-bottom: 1px solid color(blue, normal);
								transition: border-color time(default) ease(inout);
							}

							&.focus,
							&.not-empty {
								.placeholder {
									opacity: 0;
									transform: translateX(10px);
								}
							}

							&.invalid .field {
								border-color: red;
							}
						}

						.form-submit {
							button {
								display: inline-block;

								svg {
									transition: transform time(default) ease(inout);
								}

								&:hover svg {
									transform: translateX(10px);
								}
							}
						}
					}
				}
			}
		}

		.navigation ul li {
			position: relative;
			text-align: right;
			opacity: 0;
			transform: translateX(10px);
			transition: opacity time(default) ease(inout), transform time(slow) ease(inout);
			
			&:not(:last-child) {
				margin-bottom: 35px;
			}

			&::before {
				content: '';
				background-color: red;
				position: absolute;
				top: 0;
				right: -110px;
				bottom: 0;
				width: 150px;
				z-index: 1;
				transform-origin: 100% 50%;
				transform: scaleX(0);
				transition: transform time(default) ease(inout);
			}

			a {
				color: #3C3C3C;
				font-size: 25px;
				font-weight: 500;
				display: inline-block;
				position: relative;
				z-index: 2;
				transition: letter-spacing time(default) ease(inout);
			}

			&:nth-child(1) {
				&::before {
					background-image: linear-gradient(to right, #24A6EC, #3FEFE3);
				}
			}

			&:nth-child(2) {
				&::before {
					background-image: linear-gradient(to right, #7D3FEF, #FD90E2);
				}
			}

			&:nth-child(3) {
				&::before {
					background-image: linear-gradient(to right, #FD9E90, #EFC73F);
				}
			}

			&:nth-child(4) {
				&::before {
					background-image: linear-gradient(to right, #11E379, #00DEFF);
				}
			}

			&:nth-child(5) {
				&::before {
					background-image: linear-gradient(to right, #B91E92, #FF5E5F);
				}
			}

			&:nth-child(6) {
				&::before {
					background-image: linear-gradient(to right, #184E99, #49A7E3);
				}
			}

			&:hover,
			&.active {
				&::before {
					transform: scaleX(1);
				}

				a {
					letter-spacing: 3px;
				}
			}
		}
	}

	.close {
		position: absolute;
		top: 50%;
		right: 60px;
		transform: translateY(-50%);
		z-index: 5;

		svg path {
			fill: #3C3C3C;
			transition: fill time(default) ease(inout);
		}

		&:hover svg path {
			fill: color(blue, normal);
		}
	}

	/** Open. */
	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: initial;

		.flex-wrapper {
			.info {
				&-contact {
					&-title {
						opacity: 1;
					}

					.info-listing .info-item {
						opacity: 1;
						transform: translateX(0);

						/** @each: Delay. */
						@for $i from 1 to 5 {
							&:nth-child(#{$i}) {
								transition-delay: #{.15s * $i};
							}
						}
					}
				}

				.newsletter {
					opacity: 1;
					transition-delay: .3s;
				}
			}

			.navigation	ul li {
				opacity: 1;
				transform: translateX(0);

				/** @each: Delay. */
				@for $i from 1 to 10 {
					&:nth-child(#{$i}) {
						transition-delay: #{.15s * $i + .2s};
					}
				}
			}
		}
	}
}

#main-menu-mobile {
	background-color: white;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 60;
	opacity: 0;
	visibility: hidden;
	transition: opacity time(default) ease(inout), visibility time(default) ease(inout);
	pointer-events: none;

	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: initial;
	}

	.logo {
		position: absolute;
		top: 13px;
		left: 25px;

		a {
			display: inline-block;
		}
	}

	.close {
		position: absolute;
		top: 23px;
		right: 29px;
		z-index: 5;

		svg path {
			fill: #3C3C3C;
		}
	}

	.wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			justify-content: center;
		}

		.navigation ul li {
			position: relative;
			text-align: center;
			
			&:not(:last-child) {
				margin-bottom: 15px;
			}

			a {
				color: #3C3C3C;
				font-size: 25px;
				font-weight: 500;
				display: inline-block;
				position: relative;
				z-index: 2;
				transition: letter-spacing time(default) ease(inout);
			}
		}

		.flex {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 35px 0;

			.account {
				display: flex;
				align-items: center;

				svg path {
					fill: color(grey);
					transition: fill time(default) ease(inout);
				}

				span {
					color: color(grey);
					font-size: 10px;
					font-weight: bold;
					text-transform: uppercase;
					letter-spacing: .8px;
					display: inline-block;
					position: relative;
					top: -7px;
					margin-left: 10px;
					transition: color time(default) ease(inout);
				}
			}

			.languages {
				margin-left: 60px;

				a {
					&:not(:first-child) {
						margin-left: 20px;
					}
				}
			}
		}

		.social ul {
			position: relative;
			display: flex;
			align-items: flex-end;
			justify-content: center;

			li {
				display: block;
				text-align: center;

				&:not(:last-child) {
					margin-right: 50px;
				}
				
				a {
					position: relative;
					display: inline-block;
					
					svg path {
						fill: color(grey);
					}

					span {
						color: color(grey);
						font-size: 10px;
						font-weight: bold;
						text-transform: uppercase;
						position: absolute;
						top: calc(100% + 5px);
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
		}
	}
}

#main-menu-toggler-mobile {
	position: fixed;
	top: 20px;
	right: 25px;
	width: 25px;
	height: 20px;
	z-index: 55;

	/** Desktop. */
	@media (min-width: 1200px) {
		display: none;
	}

	span {
		background-image: linear-gradient(to right, #49A8E3, #174E98);
		position: absolute;
		height: 2px;
		right: 0;
		left: 0;
		transition: background-color time(default) ease(inout);

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2) {
			top: calc(50% - 1px);
		}

		&:nth-child(3) {
			bottom: 0;
		}
	}
}
#page-filter {
  position: relative;
  width: 100%;
  padding: 60px 60px 0;
  overflow: hidden;

  /** Mobile. */
  @media (max-width: 1023px) {
    padding: 60px 0 0;
  }

  /** Section page hero. */
  .section-page-hero .background {
    background-image: linear-gradient(to right, #7D3FEF, #FD90E2);
  }

  /** Section filter. */
  .section-filter {
    padding: 100px 0;

    .flex-wrapper {
      display: flex;
      align-items: flex-start;

      /** Mobile. */
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }

      .sidebar {
        width: 200px;
        flex-shrink: 0;

        /** Mobile. */
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 50px;
        }

        &-title {
          color: color(blue, normal);
          font-size: 28px;
          margin-bottom: 50px;
        }

        &-listing {
          .item {
            &:not(:last-child) {
              margin-bottom: 30px;
            }

            &-title {
              color: color(blue, normal);
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 15px;
            }

            &-options ul {
              padding: 10px 25px 10px 0;
              max-height: 150px;
              overflow: auto;

              &::-webkit-scrollbar {
                width: 3px;
              }

              &::-webkit-scrollbar-track {
                background: #f1f1f1;
              }

              &::-webkit-scrollbar-thumb {
                background: color(grey);
              }

              &::-webkit-scrollbar-thumb:hover {
                background: color(blue, normal);
              }

              li {
                &:not(:last-child) {
                  margin-bottom: 15px;
                }
                
                button {
                  position: relative;
                  color: color(grey);
                  font-size: 16px;
                  font-weight: normal;
                  padding-left: 30px;
                  text-transform: capitalize;
                  cursor: pointer;
                  transition: color time(default) ease(inout);

                  &::before {
                    content: '';
                    position: absolute;
                    top: -2px;
                    left: 0;
                    width: 15px;
                    height: 15px;
                    border: 2px solid color(grey);
                    border-radius: 5px;
                    transition: background-color time(default) ease(inout);
                  }
                }

                &:hover button {
                  color: color(blue, normal);
                }

                &.active button::before {
                  background-color: color(grey);
                }
              }
            }
          }
        }

        &-submit {
          margin-top: 50px;

          button {
            background-color: color(blue, normal);
            color: white;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 5px;
            width: 100%;
            height: 50px;
          }
        }
      }

      .results {
        flex-grow: 1;
        margin-left: 60px;

        /** Mobile. */
        @media (max-width: 767px) {
          flex-grow: unset;
          width: 100%;
          margin: 0;
        }

        &-title {
          color: color(blue, normal);
          font-size: 28px;
          margin-bottom: 50px;
        }

        &-description p {
          color: color(grey);
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
        }
      }
    }
  }
}
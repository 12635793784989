#page-portal-calendar {
	
	/** Section calendar. */
	.section-calendar {
		position: relative;
		padding: 55px 0;
		z-index: 2;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 50px 0;
		}

		.navigation ul {
			display: flex;
			align-items: center;
			justify-content: center;

			li {
				&:not(:last-child) {
					margin-right: 40px;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin-right: 0;
					}
				}

				button {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 50px;
					padding: 0 25px;
					border-radius: 30px;
					overflow: hidden;

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 100%;
						border-radius: 0px;
					}

					&::before,
					&::after {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
					}

					&::before {
						background-image: linear-gradient(to right, #C0C0C0, #DCDDDD);
						z-index: 1;
					}

					&::after {
						background-image: linear-gradient(to right, #B91E92, #FF5E5F);
						z-index: 2;
						opacity: 0;
						transition: opacity time(default) ease(inout);
					}
					
					span {
						color: #3C3C3C;
						font-size: 16px;
						font-weight: bold;
						display: inline-block;
						position: relative;
						z-index: 3;
						transition: color time(default) ease(inout);

						/** Mobile. */
						@media (max-width: 1023px) {
							line-height: 1.3;
						}
					}
				}

				&:hover button,
				&.active button {
					span {
						color: white;
					}

					&::after {
						opacity: 1;
					}
				}
			}
		}

		.tabs {
			position: relative;
			margin: 100px auto 0;
			transition: height time(default) ease(inout);
			transition-delay: .3s;

			/** Mobile. */
			@media (max-width: 1500px) {
				width: 100%;
			}
			@media (max-width: 1023px) {
				margin: 80px 0 0;
			}

			.tab-content {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				opacity: 0;
				visibility: hidden;
				transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

				&.active {
					opacity: 1;
					visibility: visible;
					transition-delay: .4s
				}

				.message {
					position: relative;
					
					p {
						color: black;
						font-size: 16px;
						font-weight: normal;
						line-height: 1.7;
						text-align: center;
					}
				}

				.flex-wrapper {
					display: flex;
					align-items: flex-start;

					/** Mobile. */
					@media (max-width: 1023px) {
						flex-wrap: wrap;
					}

					.slide {
						position: relative;
						width: 490px;

						/** Mobile. */
						@media (max-width: 1023px) {
							width: 100%;
						}

						&::before {
							content: '';
							position: absolute;
							top: 74px;
							right: 0;
							left: 0;
							height: 310px;
							z-index: 1;
							box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
							border-radius: 10px;
						}

						&-arrows {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-bottom: 20px;

							.arrow {
								svg path {
									fill: color(grey);
									transition: fill time(default) ease(inout);
								}

								&:hover svg path {
									fill: color(blue, normal);
								}

								&:nth-child(2) {
									position: relative;
									margin-left: 50px;
									bottom: -1px;
								}
							}
						}

						&-items {
							position: relative;
							width: 100%;
							z-index: 2;

							.item {
								position: relative;
								width: 100%;

								.label {
									color: color(blue, normal);
									font-size: 16px;
									font-weight: bold;
									text-transform: uppercase;
									text-align: center;
									margin-bottom: 20px;
								}

								.calendar {
									background-color: white;
									position: relative;
									width: 100%;
									border-radius: 10px;
									overflow: hidden;

									&-header {
										background-color: color(blue, normal);

										ul {
											display: flex;
											align-items: center;
											flex-wrap: wrap;

											li {
												color: white;
												font-size: 14px;
												font-weight:  bold;
												text-transform: uppercase;
												height: 50px;
												width: 14%;
												display: flex;
												align-items: center;
												justify-content: center;

												/** Mobile. */
												@media (max-width: 1023px) {
													font-size: 13px;
												}
											}
										}
									}

									&-listing {
										padding: 30px 0;

										ul {
											display: flex;
											align-items: center;
											flex-wrap: wrap;
											width: 500px;

											/** Mobile. */
											@media (max-width: 1023px) {
												width: 100%;
											}

											li {
												height: 40px;
												width: 14%;

												button {
													position: relative;
													width: 100%;
													height: 100%;
													display: flex;
													align-items: center;
													justify-content: center;

													&::before,
													&::after {
														content: '';
														position: absolute;
														top: 50%;
														left: 50%;
														transform: translate(-50%, -50%);
														width: 28px;
														height: 28px;
														border-radius: 50%;
													}

													&::before {
														transition: background-color time(fast) ease(inout);
														z-index: 1;
													}

													&::after {
														background-image: linear-gradient(to right, #B91E92, #FF5E5F);
														opacity: 0;
														transition: opacity time(fast) ease(inout);
														z-index: 2;
													}

													span {
														position: relative;
														color: #989898;
														font-size: 14px;
														font-weight: bold;
														transition: color time(default) ease(inout);
														z-index: 3;

														/** Mobile. */
														@media (max-width: 1023px) {
															font-size: 13px;
														}
													}
												}

												/** Desktop. */
												@media (min-width: 1024px) {
													&:hover button span {
														color: white;
													}
												}

												&.current button span,
												&.active button span,
												&.full button span {
													color: white;
												}

												/** Desktop. */
												@media (min-width: 1024px) {
													&:hover button::after {
														opacity: 1;
													}
												}

												&.current button::before {
													background-color: #989898;
												}

												&.active button::before {
													background-color: color(blue, normal);
												}

												&.full {
													pointer-events: none;
													
													button::before {
														background-color: #BE1E2D;
													}
												}

												&.inactive {
													opacity: .5;
													pointer-events: none;
												}
											}
										}
									}
								}
							}
						}

						&-legend {
							display: flex;
							align-items: center;
							margin-top: 20px;

							/** Mobile. */
							@media (max-width: 1023px) {
								flex-wrap: wrap;
								justify-content: center;
							}

							span {
								color: color(blue, normal);
								font-size: 15px;
								font-weight: normal;
								margin: 0;
								padding: 0;
							}

							ul {
								position: relative;
								flex-grow: 1;
								bottom: -2px;
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin-left: 20px;

								/** Mobile. */
								@media (max-width: 1023px) {
									margin: 20px 0 0;
									flex-wrap: wrap;
									justify-content: center;
								}

								li {
									position: relative;
									padding: 0 0 0 15px;

									/** Mobile. */
									@media (max-width: 1023px) {
										margin: 0 30px;

										&:not(:last-child) {
											margin-bottom: 10px;
										}
									}

									&::before {
										content: '';
										position: absolute;
										top: 50%;
										left: 0;
										transform: translateY(-50%);
										width: 10px;
										height: 10px;
										border-radius: 50%;
									}

									span {
										color: #565656;
									}

									&.current::before {
										background-color: #989898;
									}

									&.active::before {
										background-color: color(blue, normal);
									}

									&.full::before {
										background-color: #BE1E2D;
									}
								}
							}
						}
					}

					.wrapper {
						position: relative;
						padding-top: 20px;
						margin-left: 140px;

						/** Mobile. */
						@media (max-width: 1500px) {
							margin-left: 50px;
						}
						@media (max-width: 1023px) {
							padding: 0;
							margin: 80px 0 0;
						}

						&::before {
							content: '';
							background-color: #C0C0C1;
							width: 1px;
							height: 275px;
							position: absolute;
							top: 95px;
							left: -60px;

							/** Mobile. */
							@media (max-width: 1500px) {
								left: -25px;
							}
							@media (max-width: 1023px) {
								display: none;
							}
						}

						&-title {
							color: color(blue, normal);
							font-size: 16px;
							font-weight: bold;
							text-transform: uppercase;
							text-align: center;
							margin-bottom: 40px;
						}

						&-listing {
							position: relative;
							width: 100%;
							margin-top: 30px;

							&--item {
								position: relative;
								margin: 0;
								padding: 0;

								&:not(:last-child) {
									margin-bottom: 20px;
								}
							}

							&--title {
								color: #565656;
								font-size: 15px;
								font-weight: bold;
								margin-bottom: 8px;
							}

							&--items {
								display: flex;
								flex-wrap: wrap;

								.item {
									width: 55px;
									height: 30px;
									display: flex;
									align-items: center;
									justify-content: center;
									margin-bottom: 5px;

									&:not(:last-child) {
										margin-right: 5px;
									}

									button {
										position: relative;
										width: 100%;
										height: 100%;

										&::before {
											content: '';
											background-color: transparent;
											position: absolute;
											top: 0;
											right: 0;
											bottom: 0;
											left: 0;
											border-radius: 10px;
											transition: background-color time(fast) ease(inout);
											z-index: 1;
										}

										span {
											color: #565656;
											font-size: 15px;
											font-weight: normal;
											position: relative;
											z-index: 2;
											transition: color time(fast) ease(inout);
										}
									}

									&.active button span,
									&.full button span {
										color: white;
									}

									/** Desktop. */
									@media (min-width: 1024px) {
										&:hover button span {
											color: white;
										}

										&:hover button::before {
											background-color: color(blue, normal);	
										}
									}
									
									&.active button::before {
										background-color: color(blue, normal);
									}

									&.full {
										pointer-events: none;
										
										button::before {
											background-color: #BE1E2D;
										}
									}
								}
							}
						}

						&-submit {
							margin-top: 45px;
							transition: opacity time(default) ease(inout);

							&.inactive {
								opacity: .3;
								pointer-events: none;
							}

							button {
								background-image: linear-gradient(to right, #174E98, #49A7E3, #174E98);
								background-size: 200% 100%;
								background-position: left center;
								display: flex;
								align-items: center;
								justify-content: center;
								width: 220px;
								height: 50px;
								border-radius: 50px;
								transition: background-position time(default) ease(inout);

								&:hover {
									background-position: right center;
								}

								span {
									color: white;
									font-size: 16px;
									font-weight: bold;
									text-transform: uppercase;
									margin-right: 13px;
								}

								svg path {
									fill: white;
								}
							}
						}

						&-message {
							margin-top: 15px;

							p {
								color: #565656;
								font-size: 11px;
								font-weight: normal;
								line-height: 1.5;
							}
						}
					}
				}

				.description {
					text-align: center;
					margin-bottom: 60px;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin-bottom: 35px;
					}
					
					p {
						color: #565656;
						font-size: 16px;
						font-weight: normal;
						line-height: 1.4;

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 14px;
						}
					}
				}

				.consultations {
					position: relative;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					width: 100%;
					max-width: 1000px;
					margin: 0 auto;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin: 0;
					}

					.consultation {
						background-color: #F1F2F4;
						width: calc(50% - 20px);
						display: flex;
						align-items: center;
						border-radius: 10px;
						overflow: hidden;
						padding: 15px 20px;
						margin-bottom: 40px;

						/** Mobile. */
						@media (max-width: 1023px) {
							flex-wrap: wrap;
							width: 100%;
							margin-bottom: 0;
						}

						&:not(:last-child) {
							margin-right: 40px;

							/** Mobile. */
							@media (max-width: 1023px) {
								margin: 0 0 40px;
							}
						}

						&:nth-child(2n + 0) {
							margin-right: 0;
						}

						&-left {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							border-right: 1px solid #989898;
							padding: 0 40px 0 10px;
							flex-shrink: 0;
							height: 50px;

							/** Mobile. */
							@media (max-width: 1023px) {
								width: 100%;
								padding: 0 15px 15px;
								margin-bottom: 15px;
								border: none;
								border-bottom: 1px solid #989898;
							}

							&--title {
								color: #989898;
								font-size: 16px;
								font-weight: bold;
								transition: color time(default) ease(inout);
							}

							&--remove {
								margin-top: 10px;

								button {
									display: flex;
									align-items: center;

									svg path {
										fill: #BE1E2D;
										transition: fill time(default) ease(inout);
									}

									span {
										color: #BE1E2D;
										font-size: 16px;
										font-weight: bold;
										transition: color time(default) ease(inout);
										margin-left: 5px;
									}

									&:hover {
										svg path {
											fill: #989898;
										}

										span {
											color: #989898;
										}
									}
								}
							}
						}

						&-right {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							padding-left: 15px;

							/** Mobile. */
							@media (max-width: 1023px) {
								width: 100%;
								align-items: center;
								justify-content: center;
							}

							&--item {
								display: flex;
								align-items: center;

								&:not(:last-child) {
									margin-bottom: 10px;

									/** Mobile. */
									@media (max-width: 1023px) {
										margin-right: 0;
										margin-bottom: 10px;
									}
								}

								span {
									&:nth-child(1) {
										color: color(blue, normal);
										font-size: 16px;
										font-weight: bold;
										margin-right: 5px;
										transition: color time(default) ease(inout);
									}

									&:nth-child(2) {
										color: #3C3C3C;
										font-size: 16px;
										font-weight: normal;
										transition: color time(default) ease(inout);
									}
								}
							}
						}

						&.inactive {
							.consultation-left {
								&--remove {
									svg path {
										fill: #989898;
									}

									span {
										color: #989898;
									}
								}
							}

							.consultation-right {
								&--item {
									span {
										&:nth-child(1) {
											color: #989898;
										}

										&:nth-child(2) {
											color: #989898;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}